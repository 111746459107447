import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import "./App.css";
import { message } from "antd";
import { exists, get, missing, put } from "./utils/Storage";
import _ from "linq";
import GoServer from "./Server/GoServer";
import moment from "moment";

window.message = message;

window.getColor = (str, s = 50, l = 50) => {
  if (!str) return "#000000";

  if (
    !_.from(str.trim()).any((r) =>
      JSON.stringify(r).toLowerCase().includes(" ")
    )
  )
    str = str.trim() + " " + str.trim();

  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let h = hash % 360;
  return "hsl(" + h + ", " + s + "%, " + l + "%)";
};

window.openTab = (url) => {
  let win = window.open(url, "_blank");
  win.focus();
};

window.pop = (url) => {
  let params = "width=" + window.screen.width;
  params += ", height=" + window.screen.height;
  params += ", top=0, left=0";
  params += ", fullscreen=yes";

  let newwin = window.open(url, "windowname4", params);
  if (window.focus) {
    newwin.focus();
  }
  return false;
};

window.gup = (name) => {
  let url = window.location.href;
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  let regexS = "[\\?&]" + name + "=([^&#]*)";
  let regex = new RegExp(regexS);
  let results = regex.exec(url);
  return results == null ? null : results[1];
};

window.TitleCase = (str) => {
  if (str === null || str === undefined) str = "";

  let splitStr = str.toLowerCase().split(" ");
  if (splitStr.length === 0) return "";

  for (let i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
};

function pad(num) {
  return ("0" + num).slice(-2);
}

window.getTimeFromDate = (timestamp) => {
  if (timestamp.toString().includes(" ")) {
    let i = timestamp.toString().indexOf(" ");
    return timestamp.toString().substring(i);
  }
  return "";
};

window.sum = (arr, key) => {
  try {
    return arr.reduce((a, b) => a + (parseFloat(b[key]) || 0), 0);
  } catch (e) {
    return null;
  }
};

put("sync", false);

localStorage.setItem("api", GoServer.BASE_URL);

if (exists("admin"))
  window.document.title = "Bunifu Go - " + get("admin").role + " Panel";

put("ay", moment().year());

if (missing("ay") && exists("schedules"))
  put(
    "ay",
    _.from(get("schedules"))
      .select((r) => parseInt(r.Year))
      .orderByDescending()
      .first()
  );

window.year = parseInt(get("ay"));

ReactDOM.render(<App />, document.getElementById("root"));
