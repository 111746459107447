import React, {Component} from 'react'; 
import { Form, Button, Input, Icon, Checkbox, Layout, Col, Row, message, Card, Select, Popconfirm, Switch, InputNumber } from 'antd';
import { get, put } from '../../utils/Storage';
import moment from 'moment';
import {version} from "../../utils/Versioning";
import axios from 'axios';
import { GetParam, Params, Route } from '../../utils/Routing';

class ManageVehicle extends Component {
    state  = { 
      id: -1,
      PlateNo:"",
      Model:"",
      Color:"#ffa200",
      Capacity:50, 
      ProviderId: null, 
    } 

componentDidMount() {
  var params = Params();
  var vehicle  = {}
  if( params.id) {
    var id = params.id;
      vehicle = get('vehicles-map')[id];
    if(vehicle==null)  {
      message.error("The record does not exitst, Redirecting...");
      setTimeout(() => {
        Route("vehicles");
      }, 3000);
      return;
    }
    
    if(get('admin').role == "Transporter" ) {  
       var pid =   get('admin').user.providerid;
      vehicle.ProviderId=pid;
    }
  

    this.setState({
      id: vehicle.id,
      PlateNo: vehicle.PlateNo,
      Model: vehicle.Model,
      Color:vehicle.Color,
      Capacity:vehicle.Capacity, 
      ProviderId: Number( vehicle.ProviderId), 
    }); 
  }else{
    if(get('admin').role == "Transporter" ) {
      var pid = Number( get('admin').user.providerid);
      this.setState({ProviderId: pid});
    }
  }
}

confirm() {
    message.loading("Please wait...",0); 
  axios.delete('/vehicle/'+this.state.id)
  .then(response=>{
     message.destroy();
     message.success("Successfully deleted.");
     Route("vehicles");
  })
  .catch(error=>{
    message.destroy();
    message.error(error['message']);
  });
}

Save(e) { 
  
    e.preventDefault();

    let _vehicle =  this.state;

    if(!_vehicle["TrackerId"])
    _vehicle["TrackerId"] = null;


  message.loading("Please wait...",0);
  if(_vehicle.id>=0) {
     axios.patch('/vehicle', _vehicle)
     .then(function (response) {
       message.destroy();
       message.success("Saved");
       Route("vehicles");
     } )
     .catch(function (error) {
       message.destroy();
       message.error(error.message);
     });
 } else { 
    //post
    axios.post('/vehicle', _vehicle)
   .then(function (response) { 
     message.success("Added");
     Route("vehicles");
   })
   .catch(function (error) {
     message.error(error.message);
   });
 }

}

 render() {


  var roles =get("roles");
  var params = Params();
  var id = params.id;

   var providers = get("providers");


    return (
     <div>
       <Layout.Content>
         <Row style={{textAlign: "center"}}>
          
             <Col span={12} style={{textAlign:"left", marginLeft: '25%'}}>
              
             <Card>
               
               <Row>
               <Col span={11} style={{textAlign: "center"}}>
                 <br/><br/><br/><br/><br/>   
                 <img src="../img/bus.gif" width="95%"/>
               </Col>
               <Col span={12}>
               <Col span={24} style={{textAlign: "center"}}>
               <br/> 
               { id ?<h2>EDIT VEHICLE</h2> :  <h2>ADD VEHICLE</h2>}
          
               </Col> <br/><br/><br/>  <br/>   
               <Form className="login-form">
             
               {(get('admin').role === "Administrator" ) &&  <Form.Item>
                      <Select
                          
                             style={{ width: '100%' }}
                             value={ this.state.ProviderId} 
                             onSelect={(val)=>this.setState({ProviderId: val})}
                           >
                          <Select.Option  value={null}>Select Provider</Select.Option>
                    {
                       providers.map(r=>{
                         return <Select.Option   value={r.id}>{r.Name}</Select.Option>
                        })
                    }
                    
                         
                     </Select>
                </Form.Item>}

                <Form.Item>
                    <Input   value={this.state.PlateNo} onChange={(val)=>this.setState({PlateNo: val.target.value})} 
                    prefix={<Icon type="car" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="PlateNo" />
                </Form.Item>

                <Form.Item>
                    <Input   value={this.state.Model} onChange={(val)=>this.setState({Model: val.target.value})} 
                    prefix={<Icon type="car" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Model" />
                </Form.Item>

                <Form.Item>
                    <InputNumber defaultValue={this.state.Capacity} onChange={(val)=>this.setState({Capacity: val})} 
                    prefix={<Icon type="car" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Capacity" />
                </Form.Item>
 

                <Form.Item>
                     Select Vehicle color <input value={this.state.Color} onChange={(val)=>this.setState({Color: val.target.value})}  type="color"/>
                </Form.Item>
               
                 
                <Form.Item style={{marginTop: -15}}>
                
                 <Button loading={this.state.loading}  onClick={this.Save.bind(this)} type="primary" style={{width: '100%',marginTop: 10}} htmlType="submit" className="login-form-button">
                  ✔ Save
                </Button> 
                <Popconfirm
                  title="Are you sure to delete?"
                  onConfirm={this.confirm.bind(this)} 
                  okText="Yes"
                  cancelText="No"
                >
                   <Button hidde={this.state.id>=0}   style={{width: '100%',marginTop: 10}} type="danger" className="login-form-button" >X Delete</Button> 
                </Popconfirm>,
               
                <a href="#/vehicles" ><Button  style={{width: '100%',marginTop: 10}} type="secondary" className="login-form-button" >Close</Button></a>
                </Form.Item>
               </Form>
               </Col>

               </Row>
            
                </Card>
            </Col>
         </Row>
      </Layout.Content>
    </div>
    )
}
}
export default ManageVehicle;
