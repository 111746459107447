import React from 'react';
import Ant, {Divider, message, Tooltip} from 'antd';
import auth from "../../../Components/Auth";
import {get, linq, put} from "../../../utils/Storage";
import _ from 'linq';
import axios from "axios";
import GoWidget from "../../../Components/GoWidget";
import GoSp from "../../../Components/GoSp";

class AuthPermissions extends React.Component {
    state = {}

    constructor(props) {
        super();
        let id = window.location.hash.split("?id=")[1] || undefined;

        if (!id) {
            setTimeout(() => window.location.hash = "#/users", 3000);
            return;
        }

        let user = _
            .from(get("users"))
            .firstOrDefault(obj => obj.id == id);

        if (!user) {
            setTimeout(() => window.location.hash = "#/users", 3000);
            return;
        }

        this.state = {
            id: id,
            user: user,
            loading: false,
            page: null,
        }

        this.state.data = this.getModules();
    }

    handleChange = (module, type, val) => {
        const {data} = this.state;
        const index = data.findIndex(obj => obj.module === module);
        data[index][type] = val;
        this.setState({data: data});
    }

    handleSave = () => {
        this.setState({loading: true});
        let {data, user} = this.state;
        let payload = {};

        data.forEach(obj => {
            payload[obj.module] = {
                access: obj.access,
                modify: obj.modify,
                add: obj.add,
                delete: obj.delete,
            }
        });


        user.payload = JSON.stringify(payload);
        // Save user
        axios.patch('/user', user)
            .then((response) => {
                put("users",
                    linq("users")
                        .select(u => {
                            if (u.id === user.id)
                                return user;

                            return u;
                        })
                        .toArray());

                message.success("Saved Successfully");
                this.setState({loading: false});
            })
            .catch((error) => {

                message.error(error.message);
                this.setState({loading: false});
            });


    }

    insertSpaces(string) {
        string = string.replace(/([a-z])([A-Z])/g, '$1 $2');
        string = string.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
        return string;
    }

    getAuth = (module, _type) => {

        // get id from url

        let user = this.state.user;

        if (!user) return user;

        let payload = user.payload;

        if (!payload)
            return false;

        try {
            payload = JSON.parse(payload);
            return payload[module][_type];
        } catch (e) {
            return true;
        }
    }
    getModules = () => {
        const result = [];

        // Loop through each module in the Authorization
        for (const module in auth) {
            // continue if module is not object
            if (!auth.hasOwnProperty(module)) continue;

            // continue if module type is not object
            if (typeof auth[module] !== "string") continue;

            // Create a new object for each module with default values
            const moduleObject = {
                key: result.length + 1,
                name: this.insertSpaces(module),
                module: module,
                access: this.getAuth(module, "access"),
                modify: this.getAuth(module, "modify"),
                add: this.getAuth(module, "add"),
                delete: this.getAuth(module, "delete"),
            };

            // Add the moduleObject to the result array
            result.push(moduleObject);

        }
        return result;
    }

    render() {

        let {data, page, user, id} = this.state;

        let records = _.from(data);

        if (page !== null)
            records = records
                .where(obj => obj.access === page)

        records = records
            .select(r => {
                r.readOnly = false;

                if (r.name.startsWith("Access"))
                    r.readOnly = true;

                if (r.name.startsWith("Can"))
                    r.readOnly = true;

                if (r.name.endsWith("Report"))
                    r.readOnly = true;

                return r;
            });

        if (!user) {
            return (
                <Ant.Result
                    status="warning"
                    title={`User with id ${id} not found, Redirecting...`}
                />
            )
        }


        return (
            <Ant.Layout style={{padding: 50}}>

                <Ant.Layout.Content>
                    <Ant.Row gutter={16} style={{padding: 15}}>
                        <Ant.Col span={20} style={{padding: 26}}>
                            <Ant.Breadcrumb>
                                <Ant.Breadcrumb.Item href="/">
                                    <Ant.Icon type="home"/>
                                </Ant.Breadcrumb.Item>
                                <Ant.Breadcrumb.Item href="#/users">
                                    <Ant.Icon type="user"/>
                                    <span>Users</span>
                                </Ant.Breadcrumb.Item>
                                <Ant.Breadcrumb.Item>Permissions</Ant.Breadcrumb.Item>
                            </Ant.Breadcrumb>
                        </Ant.Col>
                        <Ant.Col span={4} align={"right"}>
                            <Ant.Button
                                onClick={this.handleSave}
                                className={"shadow"}
                                type={"primary"}
                                icon={"check"}
                                shape={"round"}
                            > Save </Ant.Button>
                        </Ant.Col>

                        <Divider>{records.toArray().length} Modules</Divider>
                        <Ant.Row gutter={16} style={{padding: 19}}>
                            <Ant.Row gutter={16}>

                                <Ant.Col span={6}>
                                    <GoWidget title={"User <" + user.email + ">"} value={user.name} icon={"user"}/>
                                </Ant.Col>
                                <Ant.Col span={6}>
                                    <Tooltip title={<ul>{_.from(user.roles).select(r => <li>r.name</li>)}</ul>}>
                                        <GoWidget title={"Roles"}
                                                  value={_.from(user.roles).select(r => r.name).count()}
                                                  icon={"user"}/>
                                    </Tooltip>

                                </Ant.Col>

                                <Ant.Col span={6}>
                                    <GoWidget title={"Approved Modules"} value={records.count(r => r.access)}
                                              icon={"check"}/>
                                </Ant.Col>
                                <Ant.Col span={6}>
                                    <GoWidget title={"Revoked Modules"} value={records.count(r => !r.access)}
                                              icon={"lock"}
                                              color={"crimson"}/>
                                </Ant.Col>
                            </Ant.Row>
                            <Divider dashed/>

                            <Ant.Col span={24}>
                                <Ant.Card className={"shadow"}
                                          extra={
                                              <Ant.Radio.Group value={page}
                                                               onChange={val => this.setState({page: val.target.value})}
                                                               buttonStyle="solid">
                                                  <Ant.Radio.Button value={null}>All</Ant.Radio.Button>
                                                  <Ant.Radio.Button value={true}>Allowed</Ant.Radio.Button>
                                                  <Ant.Radio.Button value={false}>Revoked</Ant.Radio.Button>
                                              </Ant.Radio.Group>
                                          }>

                                    <Ant.Table
                                        loading={this.state.loading}
                                        columns={
                                            [{
                                                title: '',
                                                dataIndex: 'access',
                                                key: 'access',
                                                width: 19,
                                                fixed: 'left',
                                                csv: (obj) => obj.access,
                                                render: (val, obj) =>
                                                    <Ant.Switch
                                                        checkedChildren={<Ant.Icon type="check"/>}
                                                        unCheckedChildren={<Ant.Icon type="close"/>}
                                                        checked={val}
                                                        onChange={(val) => this.handleChange(obj.module, "access", val)}
                                                    />,
                                            },
                                                {
                                                    title: '',
                                                    dataIndex: 'name',
                                                    key: 'name',
                                                    width: '500px',
                                                    fixed: 'left',
                                                    csv: (obj) => obj.name,
                                                    render: (val, obj) =>
                                                        <Ant.Typography.Text disabled={!obj.access}>
                                                            <h5> {obj.access ? '🟢' : '🔴'} <GoSp/> {val}</h5>
                                                        </Ant.Typography.Text>
                                                },
                                                {
                                                    title: '➕ ALLOW INSERT',
                                                    dataIndex: 'add',
                                                    csv: (obj) => obj.add,
                                                    key: 'add',
                                                    render: (val, obj) =>
                                                        obj.readOnly
                                                            ? <Ant.Tag>N/A</Ant.Tag>
                                                            : <Ant.Switch
                                                                disabled={!obj.access}
                                                                checked={val}
                                                                onChange={(val) => this.handleChange(obj.module, "add", val)}
                                                            />,
                                                },
                                                {
                                                    title: '✏️ ALLOW UPDATE',
                                                    dataIndex: 'modify',
                                                    key: 'modify',
                                                    csv: (obj) => obj.modify,
                                                    render: (val, obj) =>
                                                        obj.readOnly
                                                            ? <Ant.Tag>N/A</Ant.Tag>
                                                            : <Ant.Switch
                                                                disabled={!obj.access}
                                                                checked={val}
                                                                onChange={(val) => this.handleChange(obj.module, "modify", val)}
                                                            />,
                                                },
                                                {
                                                    title: '❌ ALLOW DELETE',
                                                    dataIndex: 'delete',
                                                    key: 'delete',
                                                    csv: (obj) => obj.delete,
                                                    render: (val, obj) =>
                                                        obj.readOnly
                                                            ? <Ant.Tag>N/A</Ant.Tag>
                                                            : <Ant.Switch
                                                                disabled={!obj.access}
                                                                checked={val}
                                                                onChange={(val) => this.handleChange(obj.module, "delete", val)}
                                                            />,
                                                }
                                            ]}
                                        dataSource={records.toArray()}
                                        pagination={false}
                                    />
                                </Ant.Card>

                            </Ant.Col>
                        </Ant.Row>

                    </Ant.Row>
                </Ant.Layout.Content>
            </Ant.Layout>
        )
    }
}

export default AuthPermissions;