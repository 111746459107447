import React, { Component } from "react";
import { subscribe } from "../../../utils/bunifu-redux";
import { Card, Layout, message } from "antd";
import TripsStats from "../TripsStats";
import StudentStats from "../StudentStats";
import moment from "moment";
import axios from "axios";
import auth from "../../../Components/Auth";
import { get2 } from "../../../utils/Storage";

class Dashboard extends Component {
  state = {
    name: "Dashboard",
    date: moment().format("YYYY-MM-DD"),
    loading: true,
    prevMonth: moment().format("MM"),
    data: [],
    permissions: get2("permissions"),
  };

  componentWillMount() {
    subscribe(this);
    this.LoadData();
  }

  LoadData() {
    // Make a request for a user with a given ID
    this.setState({ loading: true });
    axios
      .get("/trips?Date=" + this.state.date)
      .then(
        function (response) {
          this.setState({ data: response.data, loading: false });
        }.bind(this)
      )
      .catch(
        function (error) {
          message.error(error.message);
          this.setState({ loading: false });
        }.bind(this)
      );
  }

  render() {
    return (
      <Layout.Content>
        <Card bordered={false} loading={this.state.loading} style={{ marginTop: -15 }}>
          {/* <AttendanceStats date={this.state.date} /> */}
          {this.state.permissions.get(1) &&
            this.state.permissions.get(1).can_view == 1 &&
            auth.View(auth.AccessTrips) && (
              <TripsStats date={this.state.date} dataSource={this.state.data} />
            )}
          {this.state.permissions.get(1) &&
            this.state.permissions.get(1).can_view == 1 &&
            auth.View(auth.Students) && <StudentStats />}
        </Card>
      </Layout.Content>
    );
  }
}

export default Dashboard;
