import React from 'react';
import {Card} from 'antd';
import _ from 'linq';
import {Model} from "survey-core";
import Store from "../../../utils/Storage";

import {Survey as GoForm} from "survey-react-ui";

class Stations extends React.Component {
    state = {}

    constructor(props) {
        super();


        const model = new Model(
            {
                "pages": [
                    {
                        "name": "pageRoutes",
                        "elements": [
                            {
                                "type": "matrixdynamic",
                                "name": "formRoutes",
                                "titleLocation": "hidden",
                                "hideNumber": true,
                                "columns": [
                                    {
                                        "name": "Name",
                                        "title": "Station Name",
                                        "cellType": "text",
                                        "isRequired": true
                                    },
                                    {
                                        "name": "Cost",
                                        "title": "Default Station Cost",
                                        "cellType": "text",
                                        "inputType": "number",
                                        "min": 0,
                                        "step": 0
                                    },
                                    {
                                        "name": "Latitude",
                                        "title": "Latitude",
                                        "cellType": "text",
                                        "inputType": "number"
                                    },
                                    {
                                        "name": "Longitude",
                                        "title": "Longitude",
                                        "cellType": "text",
                                        "inputType": "number"
                                    }
                                ],
                                "defaultRowValue": {
                                    "Cost": 0
                                },
                                "confirmDelete": true,
                                "confirmDeleteText": "Do you want to delete the Route and its associated stations ?",
                                "addRowText": "Add Station",
                                "removeRowText": "Remove Route",
                                "allowRowsDragAndDrop": true
                            }
                        ],
                        "questionTitleLocation": "top",
                        "title": "Stations",
                        "description": "Route A"
                    }
                ]
            }
        )
        // Now do that dope sh**!
        this.state = {model: model, loading: false, data:  _.from(Store.get("routes"))}
    }

    render() {
        // Yo! We got the model, now we need to render that sh**!!
        let {
            model,
            loading,
            data
        } = this.state;


        model.completeText = "Save";
        model.completedBeforeHtml = " ";
        model.completedHtml = "<h4>Saving changes...</h4>";
        model.loadingHtml = "<h3>Loading ...</h3>";
        model.widthMode = "responsive";


        model.data = data.toArray();


        // Now add the event handlerr when the form is loader
        model.onComplete.add((form, options) => {
            console.table(form.data);
        });
        return (
            <>
                <Card
                    className={"shadow"}
                    bordered={false}
                    loading={loading}
                >
                    <GoForm model={model}/>
                </Card>
            </>
        )
    }
}

export default Stations;