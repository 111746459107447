import React from 'react';
import {Button, Card, Col, Layout, Result, Row} from "antd";
import {Model} from "survey-core";
import {Survey} from "survey-react-ui";
import axios from "axios";
import routing from "../../../utils/Routing";

class Verify extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            loading: false,
            schema: {
                pages: [
                    {
                        description: "Account recovery self-service",
                        elements: [
                            {
                                type: "text",
                                name: "code",
                                title: "OTP Code",
                                description: "A 6-digit character code was sent to - SMS: {phone}, Please enter the code below to verify your account [Expires in 30 mins]",
                                isRequired: true,
                                validators: [
                                    {
                                        type: "numeric",
                                        text: "The code should be 6 characters long, All numbers. ",
                                        minValue: 100000,
                                        maxValue: 999999
                                    }
                                ],
                                inputType: "number",
                                min: 100000,
                                max: 999999,
                                minErrorText: "The  SMS  sent to {phone} is 6-digit characters,",
                            },
                            {
                                type: "text",
                                name: "password",
                                visible: false,
                                visibleIf: "{code} notempty",
                                title: "New Password",
                                isRequired: true,
                                inputType: "password",
                                validators: [
                                    {
                                        type: "text",
                                        text: "Please provide more secure password (min-Length: 6)",
                                        minLength: 6
                                    }
                                ]
                            },
                            {
                                type: "text",
                                name: "confirmPassword",
                                visibleIf: "{code} notempty",
                                title: "Confirm Password",
                                isRequired: true,
                                inputType: "password",
                                validators: [
                                    {
                                        type: "text",
                                        text: "Please provide more secure password (min-Length: 6)",
                                        minLength: 6
                                    }
                                ]
                            }
                        ],
                        name: "options-page",
                        title: "Verify Account",
                    }
                ]
            }
        };
    }

    onSubmit = (data) => {


        const code = data.code;
        const password = data.password;
        const confirmPassword = data.confirmPassword;
        const phone = routing.Params("phone");


        if (password != confirmPassword) {
            window.message.error("Password mismatch");
            data.cancel()
            return;
        }


        this.setState({loading: true})

        if (phone == null || phone.length < 10) {
            window.message.error("Invalid phone number");

            // redirect to reset page after 1.7 seconds
            setTimeout(() => routing.Redirect("#/auth"), 1000);
            return;
        }

        if (!axios.defaults.baseURL.endsWith("/api"))
            axios.defaults.baseURL += "/api";

        axios
            .post("reset-password/otp?code=" + code + "&phone=" + phone + "&password=" + password + "&confirm_password=" + confirmPassword)
            .then((response) => this.setState({data: data}))
            .catch((err) => window.message.error(err.message))
            .finally(() => {
                this.setState({loading: false})
                // redirect to login page
                setTimeout(() => routing.Redirect("#/auth"), 5000);
            })

    }


    render() {

        let {
            loading,
            data,
            schema,
        } = this.state;
        const phone = "*****" + routing.Params("phone").substring(3);

        schema["pages"][0]["elements"][0]["description"] = schema["pages"][0]["elements"][0]["description"].replace("{phone}", phone);

        // replace {phone} for minErrorText
        schema["pages"][0]["elements"][0]["minErrorText"] = schema["pages"][0]["elements"][0]["maxErrorText"] = schema["pages"][0]["elements"][0]["minErrorText"].replace("{phone}", phone);


        let form = new Model(schema);
        form.data = data;
        form.completeText = "Verify Code";
        form.completedHtml = "<h4>Hold on, Just a moment...</h4>";
        form.showQuestionNumbers = "off";
        form.onComplete.add((sender, options) => this.onSubmit(sender.data));

        return (
            <Layout>
                <Layout.Content>
                    <Row>
                        <Col span={8}/>
                        <Col span={8} style={{marginTop: "5%"}}>
                            <Card
                                loading={loading}
                                className={"shadow"}
                                title={<img src={"./img/logo2.png"} style={{float: "right"}} height={35}/>}>
                                {data ?
                                    <Result
                                        status="success"
                                        title="Successful"
                                        subTitle={'Successful verification. You can now login to your account [Redirecting in 5 seconds...]'}
                                    />
                                    :
                                    <>
                                        <Survey
                                            model={form}
                                        />
                                        <Col span={24} align={"center"}>
                                            <Button
                                                size={"large"}
                                                type="link"
                                                onClick={() => {
                                                    this.setState({loading: true})
                                                    const phone = routing.Params("phone");

                                                    let payload = {phone: phone}

                                                    if (!axios.defaults.baseURL.endsWith("/api"))
                                                        axios.defaults.baseURL += "/api";


                                                    axios
                                                        .post("forgot-password", payload)
                                                        .then((response) => window.message.success(response.data.message))
                                                        .catch((err) => window.message.error(err.message))
                                                        .finally(() => this.setState({loading: false}))
                                                }}
                                            >
                                                ⚠️ If the OTP code is missing or expired, click here to resend.
                                            </Button>
                                            <br/>
                                            <br/>
                                        </Col>

                                    </>

                                }
                            </Card>
                        </Col>
                        <Col span={8}/>
                    </Row>
                </Layout.Content>
            </Layout>
        );
    }


}

export default Verify;