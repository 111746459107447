import React, {Component} from 'react';
import {setState, subscribe, getState, getInstance} from '../../utils/bunifu-redux';
import { DatePicker, Select, message } from 'antd';

import {get,put,exists,remove, keyValue} from "../../utils/Storage";
import moment from 'moment';
import Axios from 'axios';
import Bootsrap from './BootSrap';

class ProviderDay extends Component {
state = {
   name: 'ProviderDay',
   loading: false,
   date:  moment().format('YYYY-MM-DD'), //moment('2019-04-30', 'YYYY-MM-DD').format('YYYY-MM-DD'),//,
   data: []
}
 componentWillMount() {
  subscribe(this);
}
componentDidMount() {
  this.LoadData();
}
LoadData() {
  // Make a request for a user with a given ID
  this.setState({loading: true});
      Axios.get('/trips?Date='+this.state.date+"&showStudents=true")
      .then(function (response) {
         this.setState({data: response.data,loading: false});
      }.bind(this))
      .catch(function (error) {
        message.error(error.message);
        this.setState({loading: false});
      }.bind(this));

}
 render() {
   if(this.state.loading){
            return(<div align='center' style={{width: '100%'}} class="container" >
              <Bootsrap/>
            <br/><br/>  <br/><br/>
            <div class="spinner-grow text-success" role="status">
              <span class="sr-only"></span>
            </div><div class="spinner-grow text-success" role="status">
              <span class="sr-only"></span>
            </div><div class="spinner-grow text-success" role="status">
              <span class="sr-only">Please Wait...</span>
            </div>
            </div>);
   }


   //do filtering here
var data = this.state.data;
    data = data.filter(r=>r.StartedAt.includes(this.state.date));
 
var morning  = data.filter((trip)=> trip.Direction==='To School');
var evening  = data.filter((trip)=> trip.Direction==='From School');
 

window.totRoutecost= 0;
window.totStationcost= 0;

window.totRoutecostE= 0;
window.totStationcostE= 0;

return (
<div  class="container">
<Bootsrap/>
  <nav class="navbar navbar-light bg-light hidden-print">
      <a class="navbar-brand"> </a>
      <form class="form-inline">
      <DatePicker size={"large"} defaultValue={moment(this.state.date, 'YYYY-MM-DD')} format={'YYYY-MM-DD'}  onChange={(val)=>{ this.setState({date: val.format('YYYY-MM-DD')});}} />&nbsp;
        <Select
        showSearch
        size={"large"}
        style={{ width: 200 }}
        placeholder="Select Provider"
        optionFilterProp="children"
        >
        <Select.Option value='Uber'>Uber</Select.Option>
        </Select>&nbsp;
        <button class="btn btn-outline-success my-2 my-sm-0" type="submit" onClick={()=>this.LoadData()}>Generate Report</button>
      </form>
    </nav>
    <br/><br/>
    <img src="../img/logo2.png"  width="150"/>
    <hr/>
    <h5><b>Billing Daily Report (All Providers)<span style={{float: 'right'}}>{moment(this.state.date, 'YYYY-MM-DD').format('LL')}</span></b> </h5>  
         
      <hr/> 
     <h6><b>Morning Schedulle (To School)</b></h6> 
    <table class="table table-striped table-sm">
  <thead  style={{backgroundColor: '#00AF50'}}>
    <tr>
      <th scope="col"></th>
      <th scope="col">Vehicle</th>  
      <th scope="col">Students</th>  
      <th scope="col">Route</th>  
      <th scope="col">Stations</th> 
      <th scope="col">Distance</th>
      <th scope="col">Route Cost</th>
      <th scope="col">Station Cost</th>
      <th scope="col">Distance Cost</th>
    </tr>
  </thead>
  <tbody>
     
   {

     morning.map((trip,idx)=>{

       var schedulle  = get('schedules-map')[trip.ScheduleId];
       var Vehicle = get('vehicles-map')[schedulle.VehicleId];
       var route = get("routes-map")[trip.RouteId];
       var stations = route.stations;
       var Students =  trip.Students;

       window.totRoutecost += (route.Cost*1);
       window.totStationcost += (window.sum(trip.Students.map(r=>get('stations-map')[r.StationId]),'Cost')*1);
       return   <tr>
                  <th scope="row">{idx+1}</th>
                  <td>{Vehicle.PlateNo}</td>
                  <td>{trip.Students.length}</td>
                  <td>{trip.Route}</td>   
                  <td>{stations.length }</td>  
                  <td>0 Km</td>  
                  <td>Sh {route.Cost}</td>
                  <td>sh {window.sum(trip.Students.map(r=>get('stations-map')[r.StationId]),'Cost')}</td>
                  <td>Sh {0}</td>
                </tr>
       })
   }   
    
  </tbody>
  <thead  style={{backgroundColor: '#00AF50'}}>
    <tr>
     
      <th scope="col"></th> 
      <th scope="col"></th>
      <th scope="col"></th>
      <th scope="col"></th>
      <th scope="col"></th>
      <th scope="col"></th>
      <th scope="col">Sh {window.totRoutecost }</th>
      <th scope="col">Sh {window.totStationcost}</th>
      <th scope="col">Sh 0</th>
    </tr>
  </thead>
</table>
<br/>
<hr/>   
     <h6><b>Evening Schedulle (From School)</b></h6> 
     <table class="table table-striped table-sm">
     <thead  style={{backgroundColor: '#00AF50'}}>
    <tr>
      <th scope="col"></th>
      <th scope="col">Vehicle</th>  
      <th scope="col">Students</th>  
      <th scope="col">Route</th>  
      <th scope="col">Stations</th> 
      <th scope="col">Distance</th>
      <th scope="col">Route Cost</th>
      <th scope="col">Station Cost</th>
      <th scope="col">Distance Cost</th>
    </tr>
  </thead>
  <tbody>
     
  {

evening.map((trip,idx)=>{

  var schedulle  = get('schedules-map')[trip.ScheduleId];
  var Vehicle = get('vehicles-map')[schedulle.VehicleId];
  var route = get("routes-map")[trip.RouteId];
  var stations = route.stations;
  var Students =  trip.Students;

  window.totRoutecostE += (route.Cost*1);
  window.totStationcostE += (window.sum(trip.Students.map(r=>get('stations-map')[r.StationId]),'Cost')*1);
  return   <tr>
             <th scope="row">{idx+1}</th>
             <td>{Vehicle.PlateNo}</td>
             <td>{trip.Students.length}</td>
             <td>{trip.Route}</td>   
             <td>{stations.length }</td>  
             <td>0 Km</td>  
             <td>Sh {route.Cost}</td>
             <td>sh {window.sum(trip.Students.map(r=>get('stations-map')[r.StationId]),'Cost')}</td>
             <td>Sh {0}</td>
           </tr>
  })
}   

    

  </tbody>
  <thead  style={{backgroundColor: '#00AF50'}}>
  <tr>
     
     <th scope="col"></th> 
     <th scope="col"></th>
     <th scope="col"></th>
     <th scope="col"></th>
     <th scope="col"></th>
     <th scope="col"></th>
     <th scope="col">Sh {window.totRoutecostE }</th>
     <th scope="col">Sh {window.totStationcostE}</th>
     <th scope="col">Sh 0</th>
   </tr>
  </thead> 
  <hr/>
  <thead  style={{backgroundColor: '#00AF50'}}>
    <tr>
    <th scope="col"></th> 
     <th scope="col"></th>
     <th scope="col"></th>
     <th scope="col"></th>
     <th scope="col"></th>
     <th scope="col"></th>
     <th scope="col">Sh {window.totRoutecost + window.totRoutecostE }</th>
     <th scope="col">Sh {window.totStationcost + window.totStationcostE}</th>
     <th scope="col">Sh 0</th>
    </tr>
  </thead>
</table> 
</div>)
}
}
export default ProviderDay;