import {Avatar, Badge, Button, Card, Col, Drawer, Icon, Radio, Rate, Row, Statistic, Table, Tag} from "antd";
import React, {useState} from "react";
import Store from "../../../utils/Storage";
import storage from "../../../utils/Storage";
import Schedule from "./Schedule";
import kimTools from "../../../utils/KimTools";
import KimTools from "../../../utils/KimTools";
import _ from "linq"

const {Meta} = Card;
let users = storage.map("users");
let vehicles = Store.map("vehicles");
let routes = Store.map("routes")
const ScheduleCard = (props) => {
    // Give user options
    const {compact, rating} = props

    const [_page, Page] = useState("");
    const [_expand, Expand] = useState(false);
    const [_selected, Selection] = useState(null);


    let schedules = props.schedules;
    let schedule = schedules[0];


    let vehicle = vehicles[schedule.VehicleId];
    let provider = Store.map("providers")[vehicle.ProviderId];

    vehicle.schedules = schedules;

    let schedulesFiltered = schedules
        .filter(
            (schedule) => schedule.Direction
                .includes(_page));


    let _users = _
        .from(storage.get("users"))

    schedulesFiltered = _.from(schedulesFiltered)
        .select(r => {
            r.Driver = _users.firstOrDefault(u => u.id === r.DriverId)

            if (!r.Driver)
                r.Driver = {name: "Unknown"}

            r.Agent = _users.firstOrDefault(u => u.id === r.AttendantId)

            if (!r.Agent)
                r.Agent = {name: "Unknown"}

            return r;
        })
        .toArray();


    const color = window.getColor(vehicle.PlateNo, 50, 50);


    const columns = [
        // Add avatar
        {
            title: '',
            dataIndex: 'Id',
            render: (id, schedule) =>
                <Avatar
                    size={"small"}
                    style={{backgroundColor: schedule.Direction === "To School" ? color : color}}>
                    <Icon type={schedule.Direction === "To School" ? "arrow-left" : "arrow-right"}/>
                </Avatar>
        },
        {
            title: '',
            dataIndex: 'Name',
            key: 'Name',
            render: (name, schedule) => <Button disabled={props.readOnly || false}
                                                style={{color: color}}
                                                onClick={() => Selection(schedule)}
                                                type="link">{kimTools.strings.toTitleCase(name)}</Button>
        },
        {
            visible: false,
            title: '',
            dataIndex: 'Direction',
            key: 'Direction',
        }, {
            title: 'Route',
            dataIndex: 'RouteId',
            key: 'Route',
            render: (routeId) => <Tag shape={"circle"}
                                      icon={"environment"}> {routes[routeId] && kimTools.strings.toTitleCase(routes[routeId].Name)} </Tag>
        },
        {
            title: 'Driver',
            dataIndex: 'DriverId',
            render: (driverId, obj) => <Tag shape={"circle"}
                                            icon={"user"}> {kimTools.strings.toTitleCase(obj.Driver.name)} </Tag>
        }, {
            title: 'Agent',
            dataIndex: 'AttendantId',
            render: (agentId, obj) => <Tag shape={"circle"}
                                           icon={"user"}> {kimTools.strings.toTitleCase(obj.Agent.name)} </Tag>
        },
    ];


    return (
        <Card
            style={{
                width: "100%",
                marginTop: 15
            }}
            className={"shadow"}
            extra={rating && <Rate disabled defaultValue={5}/>}
            // actions={[<Button onClick={() => Expand(!_expand)} icon={_expand ? "up" : "down"} shape={"round"}></Button>]}
        >


            {_selected && <Drawer
                title={<>
                    <Avatar style={{backgroundColor: color, marginTop: 5}}>
                        {vehicle && <h3
                            style={{color: "white"}}> {vehicle.PlateNo[0] + vehicle.PlateNo[1] + vehicle.PlateNo[2]}</h3>}
                    </Avatar>
                    <h5 style={{marginLeft: 50, marginTop: -25}}>
                        Schedule {_selected && _selected.Name}
                    </h5>

                </>}
                width={"85%"}
                closable={true}
                footer={null}
                // maskClosable={false}
                onClose={() => Selection(null)}
                visible={_selected != null}>
                <Schedule dataSource={_selected}/>
            </Drawer>}


            <Meta
                avatar={
                    <Badge style={{backgroundColor: color}} count={vehicle.schedules.length}>
                        <Avatar style={{backgroundColor: color, marginTop: 5}}>
                            {vehicle && <h3
                                style={{color: "white"}}> {vehicle.PlateNo[0] + vehicle.PlateNo[1] + vehicle.PlateNo[2]}</h3>}
                        </Avatar>
                    </Badge>}
                title={<span>
                    <h4> {vehicle.PlateNo} </h4>
                       <Badge
                           count={provider && KimTools.strings.toTitleCase(provider.Name)}
                           style={{
                               fontSize: 14,
                               backgroundColor: color,
                           }}/>
                   </span>}

            />
            <Row gutter={24}>

                {compact &&
                    <Col span={24}>
                        <Row gutter={10} style={{marginTop: 30}}>
                            <Col span={3} style={{margin: 10}}>
                                <Statistic title="Schedules" value={"  " + schedules.length}
                                           prefix={<Icon style={{color: color}} type="calendar"/>}/>
                            </Col>
                            <Col span={3} style={{margin: 10}}>
                                <Statistic title="To School" value={"  " + 0}
                                           prefix={<Icon style={{color: color}} type="usergroup-add"/>}/>
                            </Col>
                            <Col span={3} style={{margin: 10}}>
                                <Statistic title="From School" value={"  " + 0}
                                           prefix={<Icon style={{color: color}} type="usergroup-add"/>}/>
                            </Col>
                            <Col span={3} style={{margin: 10}}>
                                <Statistic title="Trips" value={"  " + 0}
                                           prefix={<Icon style={{color: color}} type="usergroup-add"/>}/>
                            </Col>
                            <Col span={3} style={{margin: 10}}>
                                <Statistic title="Capacity" value={"  " + 0}
                                           prefix={<Icon style={{color: color}} type="usergroup-add"/>}/>
                            </Col>
                            <Col span={3} style={{margin: 10}}>
                                <Statistic title="Sessions" value={"  " + 0}
                                           prefix={<Icon style={{color: color}} type="usergroup-add"/>}/>
                            </Col>
                        </Row>
                    </Col>}

                {compact && <>

                    <Col span={24} style={{marginTop: 15}}>
                        <div>

                            <Row gutter={16}>
                                <Col span={24}>
                                    <Radio.Group value={_page}
                                                 onChange={(r) => Page(r.target.value)}
                                                 buttonStyle="solid">
                                        <Radio.Button style={_page === "" ? {backgroundColor: color} : {}}
                                                      value="">All</Radio.Button>
                                        <Radio.Button style={_page === "To School" ? {backgroundColor: color} : {}}
                                                      value="To School">To School</Radio.Button>
                                        <Radio.Button style={_page === "From School" ? {backgroundColor: color} : {}}
                                                      value="From School">From School</Radio.Button>
                                    </Radio.Group>
                                </Col>
                                <br/>
                                <br/>
                                <Table
                                    size={"middle"}
                                    dataSource={schedulesFiltered}
                                    columns={columns}
                                    pagination={false}
                                />
                            </Row>
                        </div>
                    </Col>
                </>
                }

            </Row>

        </Card>
    );
};


export default ScheduleCard;