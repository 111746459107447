import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  Button,
  Input,
  Alert,
  Select,
  AutoComplete,
  Empty,
  Spin,
  Col,
  Row,
} from "antd";
import {
  exportToExcel,
  exportToCSV,
  exportToPDF,
} from "../../utils/generateReports";
import "antd/dist/antd.css";

const { Option } = Select;

const columns = [
  {
    title: "ADMISSION",
    dataIndex: "Adm",
    key: "adm",
    width: 150,
  },
  {
    title: "STUDENT",
    dataIndex: "Student",
    key: "student",
    width: 150,
  },
  {
    title: "CLASS",
    dataIndex: "Class",
    key: "class",
    width: 150,
  },
  {
    title: "STREAM",
    dataIndex: "Stream",
    key: "stream",
    width: 150,
  },
  {
    title: "SUBSCRIPTION",
    dataIndex: "Subscription",
    key: "subscription",
    width: 150,
  },
  {
    title: "DIRECTION",
    dataIndex: "Direction",
    key: "direction",
    width: 150,
  },
  {
    title: "ROUTES",
    dataIndex: "Route",
    key: "routes",
    width: 150,
  },
  {
    title: "STATIONS",
    dataIndex: "Station",
    key: "stations",
    width: 150,
  },
  {
    title: "DATE",
    dataIndex: "Date",
    key: "date",
    width: 150,
  },
];

const DailyStudentActivity = () => {
  const [busy, setBusy] = useState(false);
  const [text, setText] = useState("Generate Report");
  const [results, setResults] = useState([]);
  const [error, setError] = useState("");
  const [startDate, setStartDate] = useState(
    new Date().toLocaleDateString("en-CA") // Ensures 'YYYY-MM-DD' format in local time
  );
  const [endDate, setEndDate] = useState(
    new Date().toLocaleDateString("en-CA") // Ensures 'YYYY-MM-DD' format in local time
  );
  const [token, setToken] = useState("");
  const [students, setStudents] = useState([]);
  const [providers, setProviders] = useState([]);
  const [classes, setClasses] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState(students);
  const [baseUrl, setBaseUrl] = useState("");
  const [selectedStudent, setSelectedStudent] = useState("");
  const [selectedProvider, setSelectedProvider] = useState("");
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedRoute, setSelectedRoute] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);

  useEffect(() => {
    const account = JSON.parse(localStorage.getItem("account"));
    const BaseUrl = localStorage.getItem("api");

    if (account && account.access_token) {
      setToken(account.access_token);
    } else {
      setError("Missing authentication token. Please log in again.");
    }

    if (BaseUrl) {
      setBaseUrl(BaseUrl);
    } else {
      setError("Missing base URL. Please configure your settings.");
    }

    // Fetch filter data from local storage
    const storedStudents = JSON.parse(localStorage.getItem("students")) || [];
    const storedClasses = JSON.parse(localStorage.getItem("classes")) || [];
    const storedRoutes = JSON.parse(localStorage.getItem("routes")) || [];
    const storedProviders = JSON.parse(localStorage.getItem("providers")) || [];
    setStudents(storedStudents);
    setClasses(storedClasses);
    setRoutes(storedRoutes);
    setProviders(storedProviders);
  }, []);

  useEffect(() => {
    setFilteredStudents(students); // Set initial filtered students to all students
  }, [students]);

  const handleStudentSearch = (value) => {
    if (!value) {
      setFilteredStudents(students); // Display all students when the search field is empty
    } else {
      const lowercasedValue = value.toLowerCase();
      const filtered = students.filter((student) =>
        student.Name.toLowerCase().includes(lowercasedValue)
      );
      setFilteredStudents(filtered);
    }
  };

  const changeHandler = (text) => {
    setSelectedStudent(text);
  };

  const calculateTotals = (data) => {
    const uniqueAdms = new Set();
    const uniqueNames = new Set();
    const uniqueClasses = new Set();
    const uniqueStreams = new Set();
    const uniqueStations = new Set();
    const uniqueSubscriptions = new Set();
    const uniqueDirections = new Set();
    const uniqueRoutes = new Set();
    const uniqueStudents = new Set();
    const uniqueDates = new Set();

    data.forEach((item) => {
      // Add to the sets to track unique values
      uniqueAdms.add(item.Adm);
      uniqueNames.add(item.Name);
      uniqueClasses.add(item.Class);
      uniqueStreams.add(item.Stream);
      uniqueSubscriptions.add(item.Subscription);
      uniqueDirections.add(item.Direction);
      uniqueRoutes.add(item.Route);
      uniqueStations.add(item.Station);
      uniqueStudents.add(item.Student);
      uniqueDates.add(item.Date);
    });

    return {
      Adm: `${uniqueAdms.size} Unique Admissions`,
      Name: `${uniqueNames.size} Unique Students`,
      Class: `${uniqueClasses.size} Unique Classes`,
      Stream: `${uniqueStreams.size} Unique Streams`,
      // RouteName: `${uniqueRoutes.size} Unique Routes`,
      Subscription: `${uniqueSubscriptions.size} Unique Subscriptions`,
      Direction: `${uniqueDirections.size} Unique Directions`,
      Route: `${uniqueRoutes.size} unique Routes`,
      Station: `${uniqueStations.size} Unique Stations`,
      Student: `${uniqueStudents.size} unique Students`,
      Date: `${uniqueDates.size} unique Dates`,
    };
  };

  const generateReport = async (page = currentPage, pageSize = 100) => {
    if (!token) {
      setError("Missing authentication token. Please log in again.");
      return;
    }

    setBusy(true);
    setText("Generating Report...");
    setError("");
    setResults([]);
    try {
      const url = `${baseUrl}/reports/student-daily-activity?Student=${selectedStudent}&Provider=${selectedProvider}&Route=${selectedRoute}&Class=${selectedClass}&StartDate=${startDate}&EndDate=${endDate}&page=${page}&pageSize=${pageSize}`;
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const { data } = await axios.get(url, { headers });

      const totalRow = calculateTotals(data.data);
      setResults([...data.data, totalRow]); // Append the total row at the end
    } catch (error) {
      setError(
        `Error fetching data: ${error.response?.data?.message || error.message}`
      );
    } finally {
      setBusy(false);
      setText("Generate Report");
    }
  };

  return (
    <div style={{ marginTop: 30 }}>
      <h4 style={{ textAlign: "center", marginBottom: 20 }}>
        Student Activity
      </h4>{" "}
      <div style={{ width: "50%", margin: "0 auto", marginTop: 30 }}>
        <Row gutter={[16, 16]} style={{ marginBottom: "40px" }}>
          <Col span={12}>
            <label htmlFor="startDate" style={{ paddingRight: 10 }}>
              Start Date
            </label>
            <Input
              type="date"
              cursor="pointer"
              size="large"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              style={{ width: "100%" }}
            />
          </Col>

          <Col span={12}>
            <label htmlFor="endDate" style={{ paddingRight: 10 }}>
              End Date
            </label>
            <Input
              type="date"
              cursor="pointer"
              size="large"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              style={{ width: "100%" }}
            />
          </Col>

          <Col span={12}>
            <label htmlFor="students" style={{ paddingRight: 10 }}>
              Students
            </label>
            <AutoComplete
              placeholder="Search for a student..."
              style={{ width: "100%" }}
              onSearch={handleStudentSearch}
              onChange={changeHandler}
              value={selectedStudent}
              size="large"
            >
              {filteredStudents.map((student) => (
                <AutoComplete.Option key={student.id} value={student.Adm}>
                  {student.Name}
                </AutoComplete.Option>
              ))}
            </AutoComplete>
          </Col>

          <Col span={12}>
            <label htmlFor="provider" style={{ paddingRight: 10 }}>
              Provider
            </label>
            <Select
              placeholder="Select Provider"
              defaultValue=""
              size="large"
              style={{ width: "100%" }}
              onChange={(value) => setSelectedProvider(value)}
              allowClear
            >
              <Option value="">All</Option>
              {providers.map((provider) => (
                <Option key={provider.id} value={provider.Name}>
                  {provider.Name}
                </Option>
              ))}
            </Select>
          </Col>

          <Col span={12}>
            <label htmlFor="class" style={{ paddingRight: 10 }}>
              Class
            </label>
            <Select
              placeholder="Select Class"
              defaultValue=""
              size="large"
              style={{ width: "100%" }}
              onChange={(value) => setSelectedClass(value)}
              allowClear
            >
              <Option value="">All</Option>
              {classes.map((classItem) => (
                <Option key={classItem} value={classItem}>
                  {classItem}
                </Option>
              ))}
            </Select>
          </Col>

          <Col span={12}>
            <label htmlFor="routes" style={{ paddingRight: 10 }}>
              Routes
            </label>
            <Select
              placeholder="Select Route"
              defaultValue=""
              size="large"
              style={{ width: "100%" }}
              onChange={(value) => setSelectedRoute(value)}
              allowClear
            >
              {/* Option for All routes */}
              <Option value="">All</Option>
              {routes.map((route) => (
                <Option key={route.id} value={route.Name}>
                  {route.Name}
                </Option>
              ))}
            </Select>
          </Col>

          <Col span={12}>
            <Button
              type="primary"
              size="large"
              onClick={generateReport}
              loading={busy}
              disabled={!token || busy}
              style={{ width: "100%", marginTop: "26px" }}
            >
              {text}
            </Button>
          </Col>
        </Row>
      </div>
      <hr />
      {error && <Alert message={error} type="error" showIcon />}
      <Spin spinning={busy}>
        {results.length > 0 ? (
          <div
            style={{
              width: "80%",
              margin: "auto",
              marginTop: "40px",
              overflowX: "auto",
            }}
          >
            <Row justify="end" style={{ marginBottom: 20 }}>
              <Col>
                <Button
                  type="primary"
                  onClick={() => exportToExcel(results, "StudentDailyActivity")}
                  style={{ marginRight: 10 }}
                >
                  Export to Excel
                </Button>
                <Button
                  type="primary"
                  onClick={() => exportToCSV(results, "StudentDailyActivity")}
                  style={{ marginRight: 10 }}
                >
                  Export to CSV
                </Button>
                <Button
                  type="primary"
                  onClick={() =>
                    exportToPDF(
                      columns,
                      results,
                      "StudentDailyActivity",
                      `Student Daily Activity Summary - Provider: ${
                        selectedProvider || "All"
                      }`,
                      [0, 175, 80],
                      "/img/go-green.png",
                      startDate,
                      endDate
                    )
                  }
                >
                  Export to PDF
                </Button>
              </Col>
            </Row>
            <Table
              columns={columns}
              dataSource={results}
              scroll={{ x: "max-content" }}
              pagination={{
                current: Number(currentPage),
                pageSize: Number(pageSize),
                total: results.length,
                onChange: (page, pageSize) => {
                  setCurrentPage(page);
                  setPageSize(pageSize);
                  generateReport(page, pageSize);
                },
              }}
            />
          </div>
        ) : (
          <div style={{ textAlign: "center", marginTop: 40 }}>
            <Empty
              description="No data available. Please generate a report."
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          </div>
        )}
      </Spin>
    </div>
  );
};

export default DailyStudentActivity;
