import React, {Component} from "react";
import {subscribe} from "../../../utils/bunifu-redux";
import {get, keyValue, put} from "../../../utils/Storage";
import Ant, {
    AutoComplete,
    Avatar,
    Badge,
    Button,
    Col,
    Divider,
    Drawer,
    Icon,
    Input,
    message,
    Popconfirm,
    Row,
    Select,
    Table,
    Tabs,
    Tag,
    Tooltip
} from "antd";
import auth from "../../../Components/Auth";
import axios from "axios";
import Message from "../Message";
import Excel from "./Excel";
import toast from "../../../Components/Toast";
import KimTools from "../../../utils/KimTools";
import kimTools from "../../../utils/KimTools";
import _ from "linq";
import SortAlphabetic from "../../../Components/SortAlphabetic";

class Schedule extends Component {
    state = {
        name: "Schedule",
        search: "",
        selClass: "",
        selStation: "",
        modal: false,
        excel: false,
        data: {
            id: -1,
        },
        students: [],
        sendMessage: false,
        messages: [],
        art: Math.floor(Math.random() * 3),
        //  Added V2
        filters: [],
        currentDataSource: [],
        selectionFilters: {},
        ShowOutsideRoutes: false,
        //  ShowAllDirections: false,
        ShowOtherSchedules: false,
        ShowInActive: false
    }

    containsObject(obj, list) {
        var i;
        for (i = 0; i < list.length; i++) {
            if (JSON.stringify(list[i]) === JSON.stringify(obj)) {
                return true;
            }
        }

        return false;
    }

    componentWillMount() {
        Ant.message.loading("Loading...");
        subscribe(this);
        this.setState({
            data: this.props.dataSource,
            students: get("students").filter(
                (s) =>
                    s.schedules.filter((ss) => ss.id == this.props.dataSource.id).length >
                    0
            ),
        });
    }

    onRouteChange = (e) => {
        //todo: filter selected routes
    }

    render() {

        const {Text, Paragraph} = Ant.Typography;

        let vehicles = get("vehicles");
        let users = get("users");

        // If Transporter, filter by provider
        if (get("admin").role == "Transporter") {
            let pid = get("admin").user.providerid;
            users = users.filter((r) => r.providerid == pid);
            vehicles = vehicles.filter((r) => r.ProviderId == pid);
        }

        var times = [];
        for (let index = 0; index < 24; index++) {
            times.push((index < 10 ? "0" : "") + index + ":00");
            times.push((index < 10 ? "0" : "") + index + ":10");
            times.push((index < 10 ? "0" : "") + index + ":20");
            times.push((index < 10 ? "0" : "") + index + ":30");
            times.push((index < 10 ? "0" : "") + index + ":40");
            times.push((index < 10 ? "0" : "") + index + ":50");
        }


        let PendingStudents = _
            .from(get("students"))
            .select(rr => {
                rr.errors = [];

                if (rr.Status != "Active")
                    rr.errors.push({
                        message:
                            <span> <strong>{window.TitleCase(rr.Name)}</strong> is <strong>{rr.Status}</strong></span>,
                        type: "error"
                    });


                let scs = _.from(rr.schedules).where(sc => sc.id != this.state.data.id && sc.Direction == this.state.data.Direction);
                if (!scs.any()) return rr;

                rr.errors.push({
                    message:
                        <span> <strong>{window.TitleCase(rr.Name)}</strong> is already added to schedule <strong>{scs.select(sc => window.TitleCase(sc.Name)).toArray()}</strong> {this.state.data.Direction}</span>,
                    type: "error"
                });
                return rr;
            })
            .toArray();


        //students for that station
        if (!this.state.ShowOutsideRoutes) {
            PendingStudents = _
                .from(PendingStudents)
                .where((r) => _.from(r.schedules).any((sc) => sc.RouteId == this.props.dataSource.RouteId))
                .toArray();
        }


        if (!this.state.ShowInActive) {
            PendingStudents = PendingStudents.filter(
                (r) => r.Status === "Active"
            );
        }


        // let  PendingStudents = Q.from( get("students"))
        // if(!this.state.page==="all") {
        //     PendingStudents =   PendingStudents.where(st => st.RouteId === this.props.dataSource.RouteId)
        // }


        //exclude already added to same schedule on search
        PendingStudents = PendingStudents.filter(
            (r) => this.state.students.filter((rr) => rr.id === r.id).length === 0
        );

        if (!this.state.ShowOtherSchedules) {
            //make sure students not added to other trips of same direction
            PendingStudents = PendingStudents.filter(
                (r) =>
                    r.schedules.filter((rr) => rr.Direction === this.state.data.Direction)
                        .length === 0
            );
        }

        // if(!this.state.ShowAllDirections) {
        //     //make sure students not added to other trips of same direction
        //     PendingStudents = PendingStudents.filter(
        //         (r) =>
        //             _.from( r.schedules).any((rr) => this.state.ShowAllDirections )
        //     );
        // }

        if (this.state.search.length > 0) {
            PendingStudents = PendingStudents.filter(
                (r) =>
                    (r.Name && r.Name.toLowerCase().indexOf(this.state.search.toLowerCase()) >= 0) ||
                    (r.Adm && r.Adm.toLowerCase().indexOf(this.state.search.toLowerCase()) >= 0)
            );
        }

        if (this.state.selClass.length > 0) {
            PendingStudents = PendingStudents.filter(
                (r) => r.Class === this.state.selClass
            );
        }

        if (this.state.selStation > 0) {
            PendingStudents = PendingStudents.filter(
                (r) => r.StationId === this.state.selStation
            );
        }

        let route = get("routes-map")[this.state.data.RouteId];

        if (this.state.data.RouteId === "") route = get("routes")[0];

        let capacity = 0;
        try {
            capacity = get("vehicles-map")[this.state.data.VehicleId].Capacity;
        } catch (error) {
        }


        // Add Filters here
        let filtered = this.state.students;


        filtered = this.state.students.filter(
            (r) =>
                (
                    (r.Name.toString() || "")
                        .toLowerCase()
                        .includes(this.state.search.toLocaleLowerCase().trim())) ||

                (r.Adm.toString() || "")
                    .toLowerCase()
                    .includes(this.state.search.toLocaleLowerCase().trim()));

        const saveChanges = () => {
            {


                //validate
                if (this.state.data.Name.length === 0) {
                    message.error("Validation error, Invalid Name");
                    return;
                }

                if (this.state.data.Direction.length === 0) {
                    message.error("Validation error, Invalid Direction");
                    return;
                }

                if (this.state.data.StartTime.length === 0) {
                    message.error("Validation error, Invalid Start Time");
                    return;
                }
                if (this.state.data.EndTime.length === 0) {
                    message.error("Validation error, Invalid End Time");
                    return;
                }
                if (!(this.state.data.RouteId > 0)) {
                    message.error("Validation error, Select Route");
                    return;
                }
                if (!(this.state.data.VehicleId > 0)) {
                    message.error("Validation error, Select Vehicle");
                    return;
                }
                if (!(this.state.data.AttendantId > 0)) {
                    message.error("Validation error, Select Attendant/Agent");
                    return;
                }
                if (!(this.state.data.DriverId > 0)) {
                    message.error("Validation error, Select Driver");
                    return;
                }

                message.loading("Updating, Please wait...", 3000);


                //save
                if (this.state.data.id && this.state.data.id >= 0) {
                    let data = this.state.data;
                    data.students = _
                        .from(this.state.students)
                        .select(ss => {
                            return {
                                id: ss.id
                            };
                        })
                        .toArray();

                    axios
                        .patch("/schedule", data)
                        .then(
                            (response) => {
                                this.setState({data: response.data});
                                message.destroy();
                                axios
                                    .get("/student")
                                    .then(
                                        function (response) {
                                            message.destroy();
                                            put("students", response.data);
                                            put("students-map", keyValue(response.data));
                                            this.props.onSave();

                                        }.bind(this)
                                    )
                                    .catch(function (error) {
                                        message.error(error["message"]);
                                        message.destroy();
                                    });
                                toast.success("Updated", " Successful 👍");
                            }
                        )
                        .catch(function (error) {
                            message.destroy();
                            message.error(error["message"]);
                        });
                } else {
                    axios
                        .post("/schedule", this.state.data)
                        .then(
                            (response) => {
                                this.setState({
                                    data: response.data,
                                });
                                message.destroy();
                                toast.success("Saved", " Added new schedule successfully");
                                message.info("Redirecting...");

                                setTimeout(() => {
                                    window.location.href = "#/schedules?id=" + response.data.id;
                                    window.location.reload();
                                }, 1000);
                            }
                        )
                        .catch((error) => {
                            message.destroy();
                            message.error(error["message"]);
                        });
                }
            }
        }

        // Get random numer in 0,1,2
        let art = this.state.art;


        return <>
            <Tabs
                defaultActiveKey={this.state.data.id > 0 ? "1" : "0"}
                tabBarExtraContent={<span style={{padding: 10}}>
                     <Button
                         onClick={() => saveChanges()}
                         type="primary"
                         hidden={auth.noUpdate(auth.Schedulles)}
                         shape="round"
                     > <Icon type="check-circle" theme="filled"/> Save
                    </Button>
                    &nbsp; &nbsp;
                    <Popconfirm
                        placement="leftTop"
                        title={
                            "This will permanently delete the schedule and related data. Proceed?"
                        }
                        onConfirm={() => {
                            message.loading("Deleting...");
                            axios
                                .delete("/schedule/" + this.state.data.id)
                                .then(
                                    function (response) {
                                        this.setState({
                                            schedule: response.data,
                                        });
                                        toast.success("Deleted", "Successful 👍");
                                        setTimeout(() => {
                                            window.location = "#/schedules";
                                            window.location.reload();
                                        }, 1000);
                                    }.bind(this)
                                )
                                .catch(function (error) {
                                    message.error(error["message"]);
                                });
                        }}
                        okText="Yes, Delete"
                        cancelText="No"
                    >
                        <Button
                            type="danger"
                            hidden={this.state.data.id < 0}
                            shape="round">
                            <Icon type="delete" theme="filled"/>
                        </Button>
                    </Popconfirm>
                </span>}
                type="line">
                <Tabs.TabPane
                    tab={<><Icon type="edit" theme="filled"/> {this.state.data.Name}</>}
                    key="0"
                    style={{padding: 50}}>
                    <Row gutter={12}>
                        <Col span={7} style={{padding: 15, alignContent: "center"}}>
                            <img src={"img/schedule" + art + ".webp"} width={"100%"}/>
                        </Col>
                        <Col span={17} style={{paddingLeft: 100}}>
                            <Col style={{padding: 15}} span={18}>
                                {/*<h5 style={{color: "gray"}}>Schedule Name</h5>*/}
                                <Input
                                    disabled={get("admin").role !== "Administrator"}
                                    size={"large"}
                                    style={{fontWeight: "bold"}}
                                    onBlur={(val) =>
                                        this.setState({
                                            data: {...this.state.data, Name: val.target.value},
                                        })
                                    }
                                    defaultValue={this.state.data.Name}
                                    placeholder="📆 • Schedule Name"
                                />
                            </Col>
                            <Col style={{padding: 15}} span={18}>
                                <label style={{color: "gray"}}>🚌 Vehicle</label>
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    onSelect={(val) =>
                                        this.setState({data: {...this.state.data, VehicleId: val}})
                                    }
                                    defaultValue={this.state.data.VehicleId}
                                    style={{width: "100%"}}
                                >
                                    {vehicles.map(function (data, idx) {
                                        return <Select.Option key={data.id} value={data.id}>
                                            {data.PlateNo +
                                                "-" +
                                                data.Model +
                                                " (" +
                                                data.Provider +
                                                ")"}
                                        </Select.Option>;
                                    })}
                                </Select>
                            </Col>
                            <br/>
                            <br/>
                            <Col style={{padding: 15}} span={12}>
                                <label style={{color: "gray"}}>👮‍ Driver</label>
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    onSelect={(val) =>
                                        this.setState({data: {...this.state.data, DriverId: val}})
                                    }
                                    defaultValue={this.state.data.DriverId}
                                    style={{width: "100%"}}
                                >
                                    {users
                                        .filter(
                                            (r) =>
                                                r.roles.filter((rr) =>
                                                    rr.name.toLocaleLowerCase().includes("driver")
                                                ).length > 0
                                        )
                                        .map(function (data, idx) {
                                            return <Select.Option Select key={data.id} value={data.id}>
                                                {data.name}
                                            </Select.Option>;
                                        })}
                                </Select>
                            </Col>
                            <Col style={{padding: 15}} span={12}>
                                <label style={{color: "gray"}}>👨‍💼 Agent</label>
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    onSelect={(val) => this.setState({
                                        data: {...this.state.data, AttendantId: val},
                                    })}
                                    defaultValue={this.state.data.AttendantId}
                                    style={{width: "100%"}}
                                >
                                    {users
                                        .filter(
                                            (r) =>
                                                r.roles.filter((rr) =>
                                                    rr.name.toLocaleLowerCase().includes("agent")
                                                ).length > 0
                                        )
                                        .map(function (data, idx) {
                                            return <Select.Option key={data.id} value={data.id}>
                                                {data.name}
                                            </Select.Option>;
                                        })}
                                </Select>
                            </Col>
                            <br/>
                            <br/>
                            <Col style={{padding: 15}} span={12}>
                                <label style={{color: "gray"}}>⌚ Start Time</label>
                                <Select
                                    disabled={get("admin").role !== "Administrator"}
                                    onChange={(val) =>
                                        this.setState({data: {...this.state.data, StartTime: val}})
                                    }
                                    defaultValue={this.state.data.StartTime}
                                    style={{width: "100%"}}
                                >
                                    {times.map(function (data, idx) {
                                        return <Select.Option Select key={data} value={data}>
                                            {data}
                                        </Select.Option>;
                                    })}
                                </Select>
                            </Col>
                            <Col style={{padding: 15}} span={12}>
                                <label style={{color: "gray"}}>⌚ End Time</label>
                                <Select
                                    disabled={get("admin").role !== "Administrator"}
                                    onChange={(val) =>
                                        this.setState({data: {...this.state.data, EndTime: val}})
                                    }
                                    defaultValue={this.state.data.EndTime}
                                    style={{width: "100%"}}
                                >
                                    {times.map(function (data, idx) {
                                        return <Select.Option Select key={data} value={data}>
                                            {data}
                                        </Select.Option>;
                                    })}
                                </Select>
                            </Col>
                            <br/>
                            <Col style={{padding: 15}} span={12}>
                                <label style={{color: "gray"}}>⛳ Route</label>
                                <Select
                                    disabled={get("admin").role !== "Administrator"}
                                    onChange={(val) =>
                                        this.setState({data: {...this.state.data, RouteId: val}})
                                    }
                                    defaultValue={this.state.data.RouteId}
                                    style={{width: "100%"}}
                                >
                                    {get("routes").map(function (data, idx) {
                                        return <Select.Option key={data.id} value={data.id}>
                                            {data.Name}
                                        </Select.Option>;
                                    })}
                                </Select>
                            </Col>
                            <Col style={{padding: 15}} span={12}>
                                <label style={{color: "gray"}}>🧭 Direction</label>
                                <Select
                                    disabled={get("admin").role !== "Administrator"}
                                    onSelect={(val) =>
                                        this.setState({data: {...this.state.data, Direction: val}})
                                    }
                                    defaultValue={this.state.data.Direction}
                                    style={{width: "100%"}}
                                >
                                    <Select.Option value="To School">To School</Select.Option>
                                    <Select.Option value="From School">From School</Select.Option>
                                </Select>
                            </Col>
                        </Col>


                    </Row>


                </Tabs.TabPane>

                {this.state.data.id > 0 &&
                    <Tabs.TabPane
                        disabled={this.state.data.id < 0}
                        tab={<><Icon type="usergroup-add"/> Subscribed Students
                            <Badge style={{marginLeft: 20, backgroundColor: '#52c41a'}} size={"small"}
                                   count={this.state.students.length + " / " + capacity}/></>}
                        key="1"

                    >
                        <AutoComplete
                            style={{borderRadius: 10, width: 300}}

                            onChange={(val) => {
                                this.setState({search: val});
                            }}
                            value={this.state.search}
                            dataSource={[]}
                            placeholder="Search added Students..."
                        />
                        &nbsp;
                        {get("admin").role === "Administrator" &&
                            <Ant.Tooltip title={"Add Students"}>


                                <Button
                                    icon="plus"
                                    type={"primary"}
                                    shape="round"
                                    onClick={() => {
                                        this.setState({modal: true});
                                    }}
                                >
                                </Button>
                            </Ant.Tooltip>
                        }

                        {/*Action Buttons: Dock / Float to right - to right*/}
                        <span style={{float: "right"}}>
                        {get("admin").role === "Administrator"
                            && <Button
                                icon="file-excel"
                                shape="round"
                                onClick={() => {
                                    this.setState({excel: true});
                                }}
                            >
                                {" "}
                                Import From Excel
                            </Button>}
                            &nbsp;
                            <Button
                                onClick={() => {
                                    var vehicles = get("vehicles-map");
                                    var stations = get("stations-map");

                                    var content = [
                                        [
                                            "Adm",
                                            "Name",
                                            "Gender",
                                            "Guardian1",
                                            "Guardian2",
                                            "Class",
                                            "Stream",
                                            "Route",
                                            "Station",
                                            "MorningSchedule",
                                            "MorningVehicle",
                                            "EveningSchedule",
                                            "EveningVehicle",
                                        ],
                                    ];
                                    filtered.forEach((data) => {
                                        var mSchedule = data.schedules.filter(
                                            (r) => r.Direction == "To School"
                                        );
                                        mSchedule = mSchedule.length > 0 ? mSchedule[0] : null;

                                        var eSchedule = data.schedules.filter(
                                            (r) => r.Direction == "From School"
                                        );
                                        eSchedule = eSchedule.length > 0 ? eSchedule[0] : null;

                                        content.push([
                                            data.Adm,
                                            data.Name,
                                            data.Gender,
                                            data.guardians.length > 0
                                                ? data.guardians[0].phone + " " + data.guardians[0].name
                                                : "",
                                            data.guardians.length > 1
                                                ? data.guardians[1].phone + " " + data.guardians[1].name
                                                : "",
                                            data.Class,
                                            data.Stream,
                                            data.route.Name,
                                            stations[data.StationId].Name,
                                            mSchedule == null ? "" : mSchedule.Name,
                                            mSchedule == null
                                                ? ""
                                                : vehicles[mSchedule.VehicleId].PlateNo,
                                            eSchedule == null ? "" : eSchedule.Name,
                                            eSchedule == null
                                                ? ""
                                                : vehicles[eSchedule.VehicleId].PlateNo,
                                        ]);
                                    });
                                    window.exportToCsv("students.csv", content);
                                }}
                                type="normal"
                                shape="round"
                                icon="download"
                            >
                            Download CSV
                        </Button>
                            &nbsp;
                            {get("admin").role === "Administrator" && <Button
                                icon="message"
                                shape="round"
                                onClick={() => {
                                    var messages = [];
                                    //loop and add messages here
                                    filtered.forEach((student) => {
                                        student.guardians.forEach((guardian) => {
                                            let obj = JSON.parse(JSON.stringify(student));
                                            obj.GuardianName = guardian.name;
                                            obj.Phone = guardian.phone;
                                            obj.Email = guardian.phone;
                                            obj.RouteName = get("routes-map")[student.RouteId].Name;
                                            obj.StationName =
                                                get("stations-map")[student.StationId].Name;
                                            obj.HimHer = guardian.Gender == "Male" ? "him" : "her";
                                            obj.HisHer = guardian.Gender == "Male" ? "his" : "hers";
                                            obj.HisHers = guardian.Gender == "Male" ? "his" : "hers";
                                            obj.SonDaughter =
                                                guardian.Gender == "Male" ? "son" : "daughter";
                                            delete obj.guardians;
                                            messages.push(obj);
                                        });
                                    });
                                    this.setState({sendMessage: true, messages: messages});
                                }}
                            >
                                {" "}
                                Send Message
                            </Button>}
                    </span>

                        <Message
                            onClose={() => this.setState({sendMessage: false})}
                            visible={this.state.sendMessage}
                            data={this.state.messages}
                        />
                        <Excel
                            route={route}
                            schedule={this.state.data}
                            students={this.state.students}
                            visible={this.state.excel}
                            onCancel={() => this.setState({excel: false})}
                            onOk={() => {
                                this.setState({excel: false});
                            }}
                            onAdd={(students) => {
                                this.state.students.push(...students);
                                this.forceUpdate();
                            }}
                        />
                        <Drawer
                            width={1000}
                            title={"Add Students to schedule " + this.state.data.Name}
                            visible={this.state.modal}
                            onClose={() => this.setState({modal: false})}
                            //maskClosable={false}
                        >

                            {/*<Ant.Select*/}
                            {/*    mode="multiple"*/}
                            {/*    style={{width: '50%'}}*/}
                            {/*    placeholder="Please filter Routes"*/}
                            {/*    //  defaultValue={['a10', 'c12']}*/}
                            {/*    //   onChange={this.onRouteChange}*/}
                            {/*>*/}
                            {/*    {get("routes").map(function (data, idx) {*/}
                            {/*        return <Ant.Select.Option key={idx} value={data.id}>{ kimTools.strings.toTitleCase( data.Name)}</Ant.Select.Option>*/}
                            {/*    })}*/}
                            {/*</Ant.Select>*/}


                            <Input.Search
                                style={{width: 250}}
                                placeholder="Search Student..."
                                onChange={(val) => this.setState({search: val.target.value})}
                            />

                            <Ant.Popover
                                placement="leftTop"
                                title={"Apply Filters"}
                                trigger="click"
                                content={
                                    <>
                                        <span style={{marginTop: 8}}><Ant.Switch checked={this.state.ShowOutsideRoutes}
                                                                                 onChange={(checked) => this.setState({ShowOutsideRoutes: checked})}
                                                                                 size={"small"}/> &nbsp; Include students outside <strong>{this.state.data.Route}</strong> route </span>
                                        <br/>
                                        {/*<span style={{marginTop: 8}}><Ant.Switch checked={this.state.ShowAllDirections} onChange={(checked)=> this.setState({ShowAllDirections : checked })} size={"small"} /> &nbsp; Include students with schedules to all directions <strong>(Current: {this.state.data.Direction})</strong> </span> <br/>*/}
                                        <span style={{marginTop: 8}}><Ant.Switch checked={this.state.ShowOtherSchedules}
                                                                                 onChange={(checked) => this.setState({ShowOtherSchedules: checked})}
                                                                                 size={"small"}/> &nbsp; Include students with other <strong>{this.state.data.Direction}</strong> schedules </span>
                                        <br/>
                                        <span style={{marginTop: 8}}><Ant.Switch checked={this.state.ShowInActive}
                                                                                 onChange={(checked) => this.setState({ShowInActive: checked})}
                                                                                 size={"small"}/> &nbsp; Include in-active / Blocked students </span>
                                        <br/>
                                    </>}

                            >
                                <Ant.Button style={{float: "right"}} icon={"filter"} shape={"circle"}
                                            type={(
                                                this.state.ShowOutsideRoutes ||
                                                this.state.ShowOtherSchedules ||
                                                this.state.ShowInActive
                                            ) ? "primary" : "default"}/>
                            </Ant.Popover>


                            {/*<Radio.Group defaultValue="a" buttonStyle="solid">*/}
                            {/*    <Radio.Button value="a">All Students</Radio.Button>*/}
                            {/*    <Radio.Button value="b">Without {this.state.data.Direction} schedules</Radio.Button>*/}
                            {/*</Radio.Group>*/}


                            <Ant.Divider orientation="left"><Ant.Typography.Text
                                type={"secondary"}>{PendingStudents.length} Records </Ant.Typography.Text></Ant.Divider>

                            <Table
                                style={{marginTop: 10}}
                                size={"middle"}
                                pagination={{pageSize: 15}}
                                columns={[
                                    {
                                        title: "",
                                        dataIndex: "id",
                                        key: "id",
                                        render: (val, obj) => <>
                                            <Ant.Popover placement="topLeft" title={`Warnings [${obj.errors.length}]`}
                                                         content={
                                                             _.from(obj.errors)
                                                                 .select(r => {
                                                                     return <div>
                                                                         <Badge status={r.type} text={r.message}/>
                                                                     </div>
                                                                 })
                                                                 .toArray()

                                                         } trigger="hover">

                                                <Button
                                                    icon="plus"
                                                    type={obj.errors.length > 0 ? "danger" : "primary"}
                                                    size={"small"}
                                                    shape="circle"
                                                    onClick={() => {
                                                        this.state.students.push(obj);
                                                        this.forceUpdate();
                                                    }}
                                                >
                                                </Button>
                                            </Ant.Popover>
                                        </>
                                    },
                                    {
                                        title: "",
                                        dataIndex: "id",
                                        render: (val, obj) => <>
                                            <Avatar
                                                size={"small"}
                                                style={{
                                                    marginLeft: 20,
                                                    backgroundColor:
                                                        obj.guardians.length > 0 ? "#AEBBBF" : "#AEBBBF",
                                                }}
                                                icon="user"
                                            />
                                        </>,
                                    },
                                    {
                                        title: "",
                                        dataIndex: "Name",
                                        render: (val, obj) => <Text strong>{KimTools.strings.toTitleCase(val)}</Text>,
                                    },
                                    {
                                        title: "",
                                        dataIndex: "Adm",
                                    },
                                    {
                                        title: "Class",
                                        dataIndex: "Class",
                                        width: 150,
                                        filters:
                                            _.from(get("classes"))
                                                .select((val, i) => ({
                                                    text: kimTools.strings.toTitleCase(val),
                                                    value: val
                                                }))
                                                .orderBy(r => r.text)
                                                .toArray(),
                                        filterMultiple: true,
                                        onFilter: (value, record) => record.Class === value,
                                        sorter: (a, b) => SortAlphabetic(a.Class, b.Class),
                                        render: txt => <Text>{kimTools.strings.toTitleCase(txt)}</Text>
                                    },
                                    {
                                        title: "Stream",
                                        dataIndex: "Stream",
                                        filters:
                                            _.from(get("streams"))
                                                .select((val, i) => ({
                                                    text: kimTools.strings.toTitleCase(val),
                                                    value: val
                                                }))
                                                .orderBy(r => r.text)
                                                .toArray(),
                                        filterMultiple: true,
                                        onFilter: (value, record) => record.Stream === value,
                                        sorter: (a, b) => SortAlphabetic(a.Stream, b.Stream),
                                    },
                                    {
                                        title: "Route",
                                        dataIndex: "RouteId",
                                        filters:
                                            _.from(get("routes"))
                                                .select((val, i) => ({
                                                    text: kimTools.strings.toTitleCase(val.Name),
                                                    value: val.id
                                                }))
                                                .orderBy(r => r.text)
                                                .toArray(),
                                        filterMultiple: true,
                                        onFilter: (value, record) => record.RouteId === value,
                                        sorter: (a, b) => SortAlphabetic(a.RouteId, b.RouteId),
                                        render: (text, obj) => (
                                            <span>
                                                <Tag size="small" hidden={text === null}>
                                                  {" "}
                                                    <Icon type="branches"/>{" "}
                                                    {kimTools.strings.toTitleCase(text !== null ? keyValue(get("routes"))[text].Name : "")}
                                                </Tag>
                                              </span>
                                        ),
                                    },
                                    {
                                        title: "Station",
                                        dataIndex: "StationId",
                                        filters: (
                                            _.from(get("stations"))
                                                .where(r => {
                                                        const {filters} = this.state;

                                                        const routeFilters =
                                                            _.from(filters)
                                                                .where(f => f.name === "RouteId")

                                                        if (!(routeFilters.any()))
                                                            return true;

                                                        return (
                                                            _.from(routeFilters.first().data)
                                                                .where(id => id === r.RouteId)
                                                                .any()
                                                        )
                                                    }
                                                )
                                                .select((val, i) => ({
                                                    text: kimTools.strings.toTitleCase(keyValue(get("routes"), "id")[val.RouteId].Name) + " ‣ " + kimTools.strings.toTitleCase(val.Name),
                                                    value: val.id
                                                }))
                                                .orderBy(r => r.text)
                                                .toArray()
                                        ),
                                        filterMultiple: true,
                                        onFilter: (value, record) => record.StationId === value,
                                        sorter: (a, b) => SortAlphabetic(a.StationId, b.StationId),
                                        render: (text, obj) => (
                                            <span>
                                            <Tag size="small" disabled hidden={text === null}>
                                              {" "}
                                                <Icon type="environment"/>{" "}
                                                {kimTools.strings.toTitleCase(text !== null ? keyValue(get("stations"))[text].Name : "")}
                                            </Tag>
                                          </span>
                                        ),
                                    },
                                    // {
                                    //     title: "Direction",
                                    //     dataIndex: "Direction"
                                    // },

                                ]}
                                onChange={
                                    (pagination, filters, sorter, extra) => {
                                        let selFilters = [];
                                        for (const filter in filters) {
                                            if (filters.hasOwnProperty(filter)) {
                                                let el = {};
                                                el.name = filter;
                                                el.data = filters[filter];
                                                if (el.data.length > 0)
                                                    selFilters.push(el);
                                            }
                                        }

                                        this.setState({
                                            filters: selFilters,
                                            currentDataSource: extra.currentDataSource
                                        })
                                    }
                                }
                                dataSource={PendingStudents}
                            />
                        </Drawer>
                        <br/>
                        <br/>
                        {/**/}
                        <Table
                            dataSource={filtered}
                            size={"middle"}
                            pagination={false}
                            scroll={{y: '50vh'}}
                            columns={[
                                {
                                    title: "",
                                    dataIndex: "",
                                    key: "actions",
                                    render: (id, obj, idx) => <>
                                        <Tooltip title="Remove Student">
                                            <Button
                                                style={{marginLeft: 30}}
                                                icon="minus"
                                                type={"danger"}
                                                size={"small"}
                                                shape="circle"
                                                onClick={() => {
                                                    idx = this.state.students.findIndex(
                                                        (r) => r.id === obj.id
                                                    );
                                                    var students = this.state.students;
                                                    students.splice(idx, 1);
                                                    this.setState({students: students});
                                                }}
                                            >
                                            </Button>
                                        </Tooltip>
                                    </>
                                },
                                {
                                    title: "",
                                    dataIndex: "id",
                                    render: (id, rec, idx) => <>
                                        <Avatar
                                            size={"small"}
                                            style={{
                                                backgroundColor: window.getColor(rec.id + " null", 50, 50),
                                                marginLeft: 50
                                            }}>
                                            <h5><b style={{color: "white"}}>{idx + 1}</b></h5>
                                        </Avatar>
                                    </>,
                                },
                                {
                                    title: "",
                                    dataIndex: "Name",
                                    key: "Name",
                                    render: (val, obj) => <Text strong>{KimTools.strings.toTitleCase(val)}</Text>,

                                },
                                {
                                    title: "Adm",
                                    dataIndex: "Adm",
                                    key: "Adm",
                                },

                                {
                                    title: "Class",
                                    dataIndex: "Class",
                                    key: "Class",
                                    render: (val, obj) => <span>{KimTools.strings.toTitleCase(val)}</span>,

                                },
                                {
                                    title: "Stream",
                                    dataIndex: "Stream",
                                    key: "Stream",
                                    render: (val, obj) => <span>{KimTools.strings.toTitleCase(val)}</span>,

                                },
                                //todo: add stations here
                                {
                                    title: "Station",
                                    dataIndex: "StationId",
                                    key: "StationId",
                                    render: (val) => <Tag>
                                        <Icon
                                            type="home"></Icon> {KimTools.strings.toTitleCase(get("stations-map")[val].Name)}
                                    </Tag>,
                                },

                            ]}
                        />
                    </Tabs.TabPane>}
                {/*{this.state.data.id >0 &&    <Tabs.TabPane*/}
                {/*        disabled={this.state.data.id < 0}*/}
                {/*        tab={<><Icon type="tool" theme="filled"/> Scheduler*/}
                {/*            <Badge style={{marginLeft: 15, backgroundColor: '#52c41a'}} size={"small"}*/}
                {/*                   count={" Beta "}/></>}*/}
                {/*        key="2"*/}
                {/*    >*/}
                {/*        <ScheduleBuilder schedule={this.state.data}/>*/}
                {/*    </Tabs.TabPane>}*/}
                {this.state.data.id > 0 && <Tabs.TabPane
                    tab={<><Icon type="printer" theme="filled"/> Reports</>}
                    key="3">

                    <iframe src={"/wwwroot/?t-calendar&id=" + this.state.data.id}
                            style={{
                                width: '100%',
                                height: '100%',
                                border: 'none',
                                position: 'absolute',
                                top: 125,
                                right: 0,
                                left: 0,
                                bottom: 0
                            }}></iframe>
                </Tabs.TabPane>}


            </Tabs>
            <Divider/>
            <br/>
            <span style={{marginRight: 50, marginTop: 7, float: "right"}}>
                    {/*    <Button*/}
                {/*        // Ac.CannotAccess(Ac.TripTimelineReport)*/}
                {/*        hidden={true}*/}
                {/*        shape="round"*/}
                {/*        type="default"*/}
                {/*        style={{*/}
                {/*            borderColor: "gray",*/}
                {/*            borderWidth: 3,*/}
                {/*            boxShadow: " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"*/}
                {/*        }}*/}
                {/*        onClick={() =>*/}
                {/*            window.pop("/wwwroot/?t-calendar&id=" + this.state.data.id)*/}
                {/*        }*/}
                {/*    >*/}
                {/*  View Report*/}
                {/*</Button>*/}

                &nbsp;

          </span>
            <br/> <br/>
        </>;
    }
}

export default Schedule;