import React, { Component } from "react";
import { subscribe } from "../../../utils/bunifu-redux";
import Ant, {
  AutoComplete,
  Avatar,
  Button,
  Card,
  Icon,
  Input,
  Layout,
  message,
  Popconfirm,
  Table,
  Tabs,
} from "antd";
import { v4 as Guid } from "uuid";
//import Gmap from './Gmap';
import Axios from "axios";
import { get, get2, keyValue, put } from "../../../utils/Storage";
import auth from "../../../Components/Auth";
import kimTools from "../../../utils/KimTools";
import _ from "linq";

class Routes extends Component {
  state = {
    name: "Routes",
    user: get("admin"),
    loading: false,
    edit: -1,
    edit2: -1,
    data: [],
    permissions: get2("permissions"),
  };

  componentWillMount() {
    subscribe(this);
    this.loadData();
  }

  loadData(showLoader = true) {
    this.setState({
      data: get("routes"),
      loading: false,
    });

    this.syncData();
  }

  update(obj) {
    obj.password = "000000";
    if (obj.id >= 0) {
      //patch
      //post
      Axios.patch("/route", obj)
        .then(
          function (response) {
            put("routes", this.state.data);
            put("routes-map", keyValue(this.state.data));
            message.success("Saved");
            this.setState({ edit: -2 });
          }.bind(this)
        )
        .catch(function (error) {
          message.error(error.message);
        });
    } else {
      //post
      Axios.post("/route", obj)
        .then(
          function (response) {
            put("routes", this.state.data);
            put("routes-map", keyValue(this.state.data));
            this.setState({ edit: -2 });
            message.success("Added");
          }.bind(this)
        )
        .catch(function (error) {
          message.error(error.message);
        });
    }
  }

  update2(obj) {
    Axios.patch("/station", obj)
      .then(
        function (response) {
          message.success("Saved");
          this.setState({ edit: -2 });
          this.syncData();
        }.bind(this)
      )
      .catch(function (error) {
        message.error(error.message);
      });
  }

  syncData() {
    let that = this;
    Axios.get("/route").then((response) => {
      put("routes", response.data);
      put("routes-map", keyValue(response.data));
      // set state

      Axios.get("/station").then((response_stations) => {
        put("stations", response_stations.data);
        put("stations-map", keyValue(response_stations.data));

        // lop all data and update the sattions
        let _routes = _.from(response.data)
          .select((r) => {
            let route_stations = _.from(response_stations.data)
              .where((r2) => r2.RouteId === r.id)
              .toArray();
            console.log(route_stations);
            r.stations = route_stations;
          })
          .toArray();
        // todo: Sort this issue
        // that.setState({  data: _routes,loading: false });

        message.success("Stations updated");
      });
      message.success("Routes Up-to date");
    });
  }

  render() {
    let data = this.state.data;

    let data2 = [];

    // if(get('admin').role !== "Transporter") {
    //   var routes  = [];
    //   var providerid = get('admin').user.providerid;
    //   var vehicles = get('vehicles').filter(r2=>r2.ProviderId==providerid);

    //   var schecdulles = get('schedules').filter(r=>{
    //     vehicles.filter(r2=>r2.ProviderId)
    //   } );
    //   // schecdulles.forEach(element => {

    //   // });

    // }
    const { Paragraph } = Ant.Typography;

    const columns = [
      {
        title: "",
        dataIndex: "Name",
        key: "Name",
        render: (val) => (
          <Avatar
            size={"small"}
            style={{ backgroundColor: window.getColor(val + " null", 50, 50) }}
          >
            <Icon type="branches" />
          </Avatar>
        ),
      },
      {
        title: "Name",
        dataIndex: "Name",
        key: "Name",
        width: 500,
        render: (val, obj, idx) =>
          obj.id === this.state.edit ? (
            <span>
              <Input
                onChange={(e) => {
                  let val = e.target.value;
                  this.state.data[idx].Name = val;
                  this.forceUpdate();
                }}
                value={val}
              />
            </span>
          ) : (
            <span>{kimTools.strings.toTitleCase(val)}</span>
          ),
      },
      {
        title: "Cost",
        dataIndex: "Cost",
        key: "Cost",
        render: (val, obj, idx) =>
          obj.id === this.state.edit ? (
            <span>
              <Input
                onChange={(e) => {
                  var val = e.target.value;
                  this.state.data[idx].Cost = val;
                  this.forceUpdate();
                }}
                value={val}
              />
            </span>
          ) : (
            <span>{val}</span>
          ),
      },
      {
        title: "",
        key: "id",
        render: (cell, row, idx) => (
          <span>
            {this.state.permissions.get(13) && this.state.permissions.get(13).can_add == 1 ? (
              <a
                href="javascript:"
                style={{ color: "#38B149" }}
                onClick={() => {
                  var newRow = {
                    RouteId: cell.id,
                    Name: "New Station",
                    Latitude: -1.2921,
                    Longitude: 36.8219,
                    Cost: "0",
                  };
                  Axios.post("/station", newRow).then(
                    function (response) {
                      this.state.data[idx].stations.splice(0, 0, response.data);
                      this.state.edit2 = response.data.id;
                      this.forceUpdate();
                      this.syncData();
                    }.bind(this)
                  );
                }}
              >
                {row.id > 0 && "Add Station"}
              </a>
            ) : null}
            &nbsp;&nbsp;&nbsp;&nbsp;
            <a
              href="javascript:"
              style={{ color: "#38B149" }}
              onClick={() => {
                var popup = require("window-popup").windowPopup;
                popup(800, 500, "gmaps-route.html?data=" + encodeURI(JSON.stringify(row)));
              }}
            >
              View Map
            </a>
          </span>
        ),
      },
      ,
      {
        title: "",
        dataIndex: "id",
        key: "id",
        render: (val, obj) => (
          <span hidden={this.state.user.role !== "Administrator"}>
            <a
              hidden={this.state.edit !== val}
              onClick={() => {
                this.update(obj);
                this.setState({ edit: -2 });
              }}
              href="javascript:"
            >
              <Icon type="check" tyle={{ textAlign: "center", color: "green" }} />
              &nbsp;&nbsp;&nbsp;&nbsp;
            </a>
            {this.state.permissions.get(13) && this.state.permissions.get(13).can_modify == 1 ? (
              <a
                hidden={this.state.edit === val}
                onClick={() => this.setState({ edit: val })}
                href="javascript:"
              >
                {" "}
                <Icon type="edit" style={{ textAlign: "center", color: "gray" }} />
                &nbsp;&nbsp;&nbsp;&nbsp;
              </a>
            ) : null}
            {this.state.permissions.get(13) && this.state.permissions.get(13).can_delete == 1 ? (
              <Popconfirm
                placement="left"
                title={"Delete Route?"}
                onConfirm={() => {
                  Axios.delete("/route/" + obj.id)
                    .then((response) => {
                      put("routes", this.state.data);
                      put("routes-map", keyValue(this.state.data));
                      message.success("Successfully deleted.");
                      this.loadData();
                    })
                    .catch((error) => {
                      message.warn(error.response.data.message);
                    });
                }}
                okText="Yes"
                cancelText="No"
              >
                <Icon type="close" style={{ textAlign: "center", color: "tomato" }} />
              </Popconfirm>
            ) : null}
          </span>
        ),
      },
    ];

    const expandedRowRender = (_route) => {
      const columns = [
        {
          title: "",
          dataIndex: "Name",
          key: "Name",
          render: (val) => (
            <Avatar size={"small"} style={{ backgroundColor: window.getColor(val) }}>
              <Icon type="number" />
            </Avatar>
          ),
        },
        {
          title: "Name",
          dataIndex: "Name",
          key: "Name",
          render: (val, obj, idx) =>
            obj.id === this.state.edit2 ? (
              <span>
                <AutoComplete
                  dataSource={get("stations").map((val, idx) => {
                    return val.Name;
                  })}
                  onChange={(val) => {
                    obj.Name = val;
                    this.forceUpdate();
                  }}
                  value={val}
                />
              </span>
            ) : (
              <span>{kimTools.strings.toTitleCase(val)}</span>
            ),
        },
        {
          title: "Cost",
          dataIndex: "Cost",
          key: "Cost",
          render: (val, obj, idx) =>
            obj.id === this.state.edit2 ? (
              <span>
                <Input
                  onChange={(e) => {
                    var val = e.target.value;
                    obj.Cost = val;
                    this.forceUpdate();
                  }}
                  value={val}
                />
              </span>
            ) : (
              <span>{val}</span>
            ),
        },
        {
          title: "Latitude",
          dataIndex: "Latitude",
          key: "Latitude",
          render: (val, obj, idx) =>
            obj.id === this.state.edit2 ? (
              <span>
                <Input
                  onPressEnter={() => {
                    this.update2(obj);
                  }}
                  onChange={(e) => {
                    var val = e.target.value;
                    obj.Latitude = val;
                    this.forceUpdate();
                  }}
                  value={val}
                />
              </span>
            ) : (
              <span>{val}</span>
            ),
        },
        {
          title: "Longitude",
          dataIndex: "Longitude",
          key: "Longitude",
          render: (val, obj, idx) =>
            obj.id === this.state.edit2 ? (
              <span>
                <Input
                  onPressEnter={() => {
                    this.update2(obj);
                  }}
                  onChange={(e) => {
                    var val = e.target.value;
                    obj.Longitude = val;
                    this.forceUpdate();
                  }}
                  value={val}
                />
              </span>
            ) : (
              <span>{val}</span>
            ),
        },
        {
          title: "",
          dataIndex: "id",
          key: "id",
          render: (val, obj) => (
            <span hidden={this.state.user.role !== "Administrator"} style={{ float: "right" }}>
              <Icon
                onClick={() => {
                  document.functionToBeCalledFromChildWindow = (param) => {
                    obj.Longitude = param.lng();
                    obj.Latitude = param.lat();
                    this.forceUpdate();
                  };
                  var popup = require("window-popup").windowPopup;
                  popup(800, 500, "gmaps.html?data=" + encodeURI(JSON.stringify(obj)));
                }}
                type="pushpin"
                style={{ textAlign: "center", cursor: "pointer", color: "blue" }}
              />{" "}
              &nbsp;&nbsp;&nbsp;&nbsp;
              <a
                hidden={this.state.edit2 !== val}
                onClick={() => {
                  this.update2(obj);
                  this.setState({ edit2: -2 });
                }}
                href="javascript:"
              >
                {" "}
                <Icon
                  type="check"
                  style={{
                    textAlign: "center",
                    color: "green",
                  }}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
              </a>
              {this.state.permissions.get(13) && this.state.permissions.get(13).can_modify == 1 ? (
                <a
                  hidden={this.state.edit2 === val}
                  onClick={() => this.setState({ edit2: val })}
                  href="javascript:"
                >
                  {" "}
                  <Icon type="edit" style={{ textAlign: "center", color: "gray" }} />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </a>
              ) : null}
              {this.state.permissions.get(13) && this.state.permissions.get(13).can_delete == 1 ? (
                <Popconfirm
                  placement="left"
                  title={"Delete Station?"}
                  onConfirm={() => {
                    Axios.delete("/station/" + obj.id)
                      .then((response) => {
                        message.success("Successfully deleted.");
                        this.loadData();
                      })
                      .catch((error) => {
                        message.error(error["message"]);
                      });
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Icon type="close" style={{ textAlign: "center", color: "tomato" }} />
                </Popconfirm>
              ) : null}
            </span>
          ),
        },
      ];
      let data = _.from(_route.stations)
        .orderBy((r) => r.Name)
        .toArray();
      console.log("Records Specific");
      console.log(_route.stations);
      return (
        <Table
          size={"small"}
          className={"shadow"}
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      );
    };
    return (
      <Layout.Content>
        {/* <Gmap visible={false} title={"Route on Map"} /> */}
        <Card bordered={false} loading={this.state.loading}>
          <Tabs
            defaultActiveKey="1"
            tabBarExtraContent={
              <span>
                <Button shape="circle" icon="sync" size={"small"} onClick={() => this.loadData()} />
                &nbsp;
                {this.state.permissions.get(13) && this.state.permissions.get(13).can_add == 1 ? (
                  <Button
                    hidden={auth.noInsert(auth.Routes)}
                    type="primary"
                    shape="round"
                    size="small"
                    onClick={() => {
                      let id = Guid();
                      this.state.data.unshift({
                        id: id,
                        Name: "Untitled route",
                        Cost: 0,
                        stations: [],
                      });
                      this.state.edit = id;
                      this.forceUpdate();
                    }}
                  >
                    Add Route
                  </Button>
                ) : null}
              </span>
            }
          >
            <Tabs.TabPane
              tab={
                <span>
                  <Icon type="branches" />
                  Routes{" "}
                </span>
              }
              key="1"
            >
              {/* {this.state.edit !== -2 &&   <Alert message="Press Enter after edit to save" type="warning" />} */}
              <Table
                size={"middle"}
                className="components-table-demo-nested"
                columns={columns}
                expandedRowRender={expandedRowRender}
                dataSource={data}
                pagination={false}
              />
            </Tabs.TabPane>
          </Tabs>
        </Card>
      </Layout.Content>
    );
  }
}

export default Routes;
