// const jsonpack = require('jsonpack/main');
import _ from "linq";

export function put(key, value) {
  try {
    if (key.endsWith("-map")) return;
  } catch (e) {}

  let str;
  try {
    str = JSON.stringify(value);
  } catch (e) {
    str = value;
  }
  localStorage.setItem(key, str);
}

export function linq(key) {
  return _.from(get(key));
}

export function from(key) {
  return _.from(get(key));
}

export function select(key, id = undefined) {
  if (id) return _.from(get(key)).firstOrDefault((r) => r.id == id);
  return _.from(get(key));
}

export function get(key, group = undefined) {
  if (group !== undefined) return keyValue(get(key), group);

  try {
    if (key.endsWith("-map")) {
      //todo:
      return map(key.replace("-map", ""));
    }
  } catch (e) {}

  let str = localStorage.getItem(key);
  try {
    let obj = JSON.parse(str);
    return obj;
  } catch (e) {
    return str;
  }
}

export function get2(key, group = undefined) {
  if (group !== undefined) return keyValue(get(key), group);

  try {
    if (key.endsWith("-map")) {
      //todo:
      return map(key.replace("-map", ""));
    }
  } catch (e) {}

  let str = localStorage.getItem(key);
  try {
    let obj = new Map(JSON.parse(str));
    return obj;
  } catch (e) {
    return str;
  }
}

export function map(key) {
  let str = localStorage.getItem(key);
  try {
    let obj = JSON.parse(str);
    return keyValue(obj);
  } catch (e) {
    return str;
  }
}

export function exists(key) {
  return localStorage.getItem(key) !== null;
}

export function missing(key) {
  return !exists(key);
}

export function remove(key) {
  try {
    if (exists(key)) {
      localStorage.removeItem(key);
      return true;
    }
  } catch (e) {}
  return false;
}

export function keyValue(arr, key = "id") {
  if (arr == null) return null;

  if (!Array.isArray(arr)) {
    return null;
  }

  let obj = {};
  arr.forEach((v) => {
    obj[v[key]] = v;
  });
  return obj;
}

export default {
  put,
  get,
  exists,
  missing,
  remove,
  map,
  from,
  linq,
};
