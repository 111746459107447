import React, {Component} from 'react';
import {setState, subscribe, getState, getInstance} from '../../utils/bunifu-redux';
import { Statistic, Select,Button, Layout, Card,Table,Tabs ,Icon,Input,Row,Col, message,Tag, Divider} from 'antd';
import { Bar, Line,Doughnut, Pie } from 'react-chartjs-2';

import {get,put,exists,remove, keyValue} from "../../utils/Storage";
import axios from 'axios';
class StudentStats extends Component {
state = {
   name: 'StudentStats', 
   loading: true
}
 componentWillMount() {
  subscribe(this);
  this.LoadData();
}

LoadData() {  
    this.setState({data: []});
     axios.get('/student')
                .then(function (response) {
                      // response.data
                      this.setState( 
                        {
                             loading: false
                        });
                        put("students",response.data);
                        put("students-map",keyValue(response.data));
                }.bind(this))
                .catch(function (error) {               
                  message.error(error['message']);
                  this.setState( 
                    {
                         loading: false
                    });
                }.bind(this));
  }
  loadGuardians() {
    this.setState({data: []});
    axios.get('/guardian')
    .then(function (response) {
        put("guardians",response.data);
        put("guardians-map",keyValue(response.data));
        this.setState( 
            {
                 loading: false
            });
    }.bind(this))
    .catch(function (error) {
      message.error(error['message']);
      this.setState( 
        {
             loading: false
        });
    }.bind(this));
}
getPieData() {
    var data ={};

    data.labels = [];
    data.datasets = [];

    var dataset1= {};
    dataset1.data= [];
    dataset1.label="Student Gender";
    dataset1.backgroundColor=['#19BCAE','#00AF50'];
 

    data.labels= ["Male","Female"];
    dataset1.data.push(get("students").filter(r=>r.Gender=="Male").length);
    dataset1.data.push(get("students").filter(r=>r.Gender=="Female").length); 

    data.datasets.push(dataset1);  
    return data;
}


getPieData2() {
    var data ={};

    data.labels = [];
    data.datasets = [];

    var dataset1= {};
    dataset1.data= [];
    dataset1.label="Operators";
    dataset1.backgroundColor=['#00AF50','#2BC6DA','#0ECE9D'];
 

    data.labels= ["Bus Agents","School Agents","Drivers"];
    dataset1.data.push(get("users").filter(r=>r.roles.filter(rr=>rr.name==="Bus Agent").length>0).length);
    dataset1.data.push(get("users").filter(r=>r.roles.filter(rr=>rr.name==="School Agent").length>0).length); 
    dataset1.data.push(get("users").filter(r=>r.roles.filter(rr=>rr.name==="Driver").length>0).length); 

    data.datasets.push(dataset1);  
    return data;
}

getWeekData() {
    var data ={};

    data.labels = [];
    data.datasets = [];

    var dataset1= {};
    dataset1.data= [];
    dataset1.label="Classes";
    dataset1.backgroundColor='#00AF50';
 

    get("classes").forEach(c => {
        data.labels.push('Class '+c);
        dataset1.data.push(get("students").filter(r=>r.Class==c).length); 
    });
 
         
        

    data.datasets.push(dataset1);  
    return data;
}
 

 render() {
  
   
return (
    <div>
        <Card bordered={false} loading={this.state.loading}>
            <br/>  <br/> 
        <Row gutter={10}>
            <Col span={12}  >
                <Doughnut data={this.getPieData()} />  
            </Col>     
            <Col span ={12} >
            <Tabs defaultActiveKey="1">
                  <Tabs.TabPane TabPane tab="Student Population Distribution" key="1"> <Bar data={this.getWeekData()} />  </Tabs.TabPane>
                {/* <Tabs.TabPane TabPane tab="Line" key="2"> <Line data={this.getWeekData()} />  </Tabs.TabPane> */}
            </Tabs>
            </Col>     
        </Row> 
         
        </Card>
</div>)
}
}
export default StudentStats;