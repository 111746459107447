import React, {Component} from 'react';
import {setState, subscribe, getState, getInstance} from '../../utils/bunifu-redux';
import { DatePicker, Select, message, Rate, Badge, Descriptions, Statistic } from 'antd';

import {get,put,exists,remove, keyValue} from "../../utils/Storage";
import moment from 'moment';
import Axios from 'axios';
import Bootsrap from './BootSrap';

class Invoice extends Component {
state = {
   name: 'ProviderDay',
    view: 0,
   loading: false,
   date:   moment().format('YYYY-MM-DD'),//,
   data: [],
   provider: get('admin').role === "Administrator" ? '' : get('providers-map')[get('admin').user.providerid].Name
}
 componentWillMount() {
  subscribe(this);
}
componentDidMount() {
   
} 

 render() {
   if(this.state.loading){
            return(<div align='center' style={{width: '100%'}} class="container" >
              <Bootsrap/>
            <br/><br/>  <br/><br/>
            <div class="spinner-grow text-success" role="status">
              <span class="sr-only"></span>
            </div><div class="spinner-grow text-success" role="status">
              <span class="sr-only"></span>
            </div><div class="spinner-grow text-success" role="status">
              <span class="sr-only">Please Wait...</span>
            </div>
            </div>);
   }



   //do filtering here
var data = this.state.data;

    data = data.filter(r=>r.StartedAt.includes(this.state.date));
 

var morning  = data.filter((trip)=> trip.Direction==='To School');
var evening  = data.filter((trip)=> trip.Direction==='From School');
 
var morningSchedulles  = get('schedules').filter((trip)=> trip.Direction==='To School').filter(s=> {
  let A = moment(this.state.date);
  let B = moment(s.created_at);
    return  B.diff(A, 'days') <= 0
 });;
var eveningSchedulles  = get('schedules').filter((trip)=> trip.Direction==='From School').filter(s=> {
  let A = moment(this.state.date);
  let B = moment(s.created_at);
    return  B.diff(A, 'days') <= 0
 });;
  
 
if(this.state.provider.length>0) {
  morning = morning.filter(r => get('vehicles-map')[ get('schedules-map')[r.ScheduleId].VehicleId].Provider === this.state.provider );
  evening = evening.filter(r => get('vehicles-map')[ get('schedules-map')[r.ScheduleId].VehicleId].Provider === this.state.provider );
    
    var morningSchedulles  = morningSchedulles.filter(r => get('vehicles-map')[ r.VehicleId].Provider === this.state.provider );
    var eveningSchedulles  = eveningSchedulles.filter(r => get('vehicles-map')[ r.VehicleId].Provider === this.state.provider );
} 
var active_students = get('students').filter(r=>r.Status==='Active');

return (
  <div  class="container" style={{marginLeft: '30%',width: '70%'}}>
     <br/>
     <br/>
        <Bootsrap/>
        <Descriptions title="SCHOOL INVOICE"> 
        <Descriptions.Item label="">Expected payment as at <b>{moment().format('MMMM  YYYY')}</b></Descriptions.Item>
        <Descriptions.Item label="Billing Method">Flat Rate</Descriptions.Item>
        <br/>
        <Descriptions.Item label="Cost Per student">sh 475</Descriptions.Item>
        <Descriptions.Item label="Payment">(Pending)</Descriptions.Item>
        <br/>
        <Descriptions.Item label="">
           <Statistic title="Active Students" value={active_students.length} />
         </Descriptions.Item>
         <Descriptions.Item label="">
           <Statistic title="Expected Amount" prefix='sh' value={active_students.length * 475} />
         </Descriptions.Item>
        </Descriptions> 
   </div>)
}
}
export default Invoice;