import React, {Component} from 'react';

class NotFound extends Component {
    state = {}

    render() {
        return (
            <div style={{textAlign: "center"}}>
                <a href={"#/" + (this.props.link || this.props.url || "")}><img src="img/404.gif" width="70%"/></a>
            </div>)
    }
}

export default NotFound;