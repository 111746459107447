import React, {Component} from 'react'; 
import { Layout, Steps, Divider, Radio, Button,Popconfirm,Result, DatePicker, Alert, Col, Row, Select, Checkbox, Table, Switch, Avatar, Icon, Statistic, message} from 'antd'; 
import { get } from '../../utils/Storage';
import axios from 'axios';
import moment from 'moment';
class PromotionWizard extends Component {
state = {
    current: 0,
    classes: [],
    year: moment().year(),
    cloneExistingSchedules: true,
    ImportStudentsToSchedules: true,
    ExcludeAlumniFromSchedules: true,
    UnsubscribeAlumniNotifications: true,
    promote: true,
    loading: false

} 
componentWillMount() {
    let classes = [];
    let tmpClasses = get('classes').reverse();
    tmpClasses.forEach((theClass,idx) => {
        // Skip alumni classes
        if(!theClass.toLowerCase().includes('alumni')) {
            classes.push({
                selected: true,
                from: theClass,
                to: idx>0? tmpClasses[idx-1]: 'Alumni'
            });
        }
    });

    this.setState({classes});
}

getCurrentYear=()=> moment().format('YYYY');
render() {
   const currentYear = this.getCurrentYear();  // Replace with AcademicYear
return (
<Layout.Content>
  <Steps     size="small"  current={this.state.current}>
    <Steps.Step title="Academic Year" icon={<Icon type="calendar" />}  />
    <Steps.Step title="Promotion" icon={<Icon type="user" />} />
    <Steps.Step title="Schedules" icon={<Icon type="solution" />}/> 
    <Steps.Step title={this.state.loading?'Please Wait...':'Done'}  icon={<Icon type={this.state.loading?'loading':'smile-o'}  />}/>
  </Steps>
   <Divider /> 
  <Layout.Content>
   {/* Academic Year */}
   <div hidden={this.state.current!==0} style={{width: '100%', align: 'center'}} >
       Please select a Year from the calendar bellow. <br/> <br/> 
       <Row>
       <Col span={12}  hidden={get('schedules').filter(r=>r.Year.toString() ===currentYear).length>0} ><Alert message="Bunifu-Go will initialize a database instance for the new academic year records." type="info" showIcon /></Col>
       <Col span={12}  hidden={get('schedules').filter(r=>r.Year.toString() === currentYear).length===0} ><Alert  message="Academic year already exixts." type="warning" showIcon /></Col>
            </Row>
       <Row><Col span={8}> <br/> 
        <Statistic style={{color: 'green'}} title="New Academic Year" value={this.state.year.toString()} precision={0} />
        <Button hidden={get('schedules').filter(r=>r.Year.toString() === currentYear).length>0} onClick={()=>this.setState({current: 1})} style={{ marginTop: 16 }} type="primary">
            Create Academic Year
        </Button>
        </Col>
       </Row>
    
        
   </div>
     {/* Promotion */}
   <div hidden={this.state.current!==1}>
       <Table
           pagination={{ pageSize: 50, hideOnSinglePage: true }} 
           scroll={{ y: 240 }}  
           columns= {
                [
                    { 
                        width: 100,
                        render: val => <Avatar style={{ backgroundColor: '#00AF50'}}> <Icon type='team'/></Avatar>
                    },
                    {
                        title: 'Promote From',
                        dataIndex: 'from', 
                        key: 'from'
                    },
                    {
                        title: 'Promote To',
                        dataIndex: 'to',
                        key: 'to',
                        render: (val,obj)=><ClassesDropdown value={val} onChange={(val)=>{obj.to = val; this.forceUpdate(); } } />
                    }
                ]
        }
         dataSource={this.state.classes}
       />
   </div>
   {/* Schedules */}
   <div hidden={this.state.current!==2}>
      <Switch defaultChecked={true} onChange={(checked)=>this.setState({cloneExistingSchedules: checked})} /> Clone all Schedules from prev Academic year. <br/> <br/>
      <Switch defaultChecked={true} onChange={(checked)=>this.setState({promote: checked})} /> Promote students to the next class. <br/> <br/>
      <Switch defaultChecked={true} onChange={(checked)=>this.setState({ImportStudentsToSchedules: checked})} /> Import students from prev Academic year to respective schedules. <br/> <br/>
      <Switch defaultChecked={true} onChange={(checked)=>this.setState({ExcludeAlumniFromSchedules: checked})} /> Exclude New Alumni students from all Schedules. <br/> <br/>
      <Switch defaultChecked={true} onChange={(checked)=>this.setState({UnsubscribeAlumniNotifications: checked})}/>  Unsubscribe New Alumni students from all Notifications (SMS/APP/Emails). <br/>
   </div>
   {/* Success */}
   <div hidden={this.state.current!==3}>
   {this.state.loading && <Result
     
    icon={<Icon type="loading" style={{color: '#1EBD60'}} />}
    title="One moment please..." 
    subTitle="We are executing the operations, might take awhile, please wait."
    sub
  />}
    { !this.state.loading && <Result
        status={"success"}
        title="Great, we have done all the operations!"
        extra={<Button type="primary" onClick={()=>this.props.onCancel()}>Next</Button>}
    />}
   </div> 
    <Divider/>
   <div hidden={this.state.current===0 || this.state.loading}>
   <Radio.Group  buttonStyle="solid" value={''} onChange={e=>{
       if(e.target.value==='prev') {
           this.setState({current: this.state.current-1});
       } else {
           this.setState({current: this.state.current+1});
       }
   }}>
          <Radio.Button value={'prev'}>Prev</Radio.Button>
          <Radio.Button disabled={this.state.current===2} value={'next'}>Next</Radio.Button>   
        </Radio.Group>
        <Popconfirm
            title="Are you sure you want proceeed with this task?"
            onConfirm={()=> {
                this.setState({current: 3,loading: true});
                  axios.post('/promote',this.state)
                            .then(function (response) {
                                this.setState({loading: false});
                            }.bind(this))
                            .catch(function (error) {
                               message.error(error.message);
                                this.setState({loading: false});              
                            });
            }} 
            okText="Yes"
            cancelText="No"
            
        ><Button hidden={this.state.current!==2} style={{float: 'right',marginTop: 10}} icon='check' type='primary' >Submit</Button>
        </Popconfirm>
   </div>
   </Layout.Content>
</Layout.Content>)
}
}
export default PromotionWizard;

class ClassesDropdown extends Component {
state = {
 
} 
 render() {
    return (
        <Select   onChange ={(val)=>this.props.onChange(val)}  style={{ width: 300 }} defaultValue={this.props.value}>
            <Select.Option  value={'Alumni'}>Alumni</Select.Option>
             {get('classes').map(r=> <Select.Option  value={r}>{r}</Select.Option> )}
         </Select>)
  }
}