import React, {Component} from 'react';
import {setState, subscribe} from '../../utils/bunifu-redux';
import {Card, Col, Icon, Row, Statistic, Tabs} from 'antd';
import {Bar, Doughnut, Line} from 'react-chartjs-2';

import {get} from "../../utils/Storage";
import moment from 'moment';

class TripsStats extends Component {
    state = {
        name: 'TripsStats',
        data: []
    }

    componentWillMount() {
        subscribe(this);
    }

    componentDidMount() {
        this.setState({
            data: this.props.dataSource
        });
    }


    getPieData() {
        var data = {};

        data.labels = [];
        data.datasets = [];

        var dataset1 = {};
        dataset1.data = [];
        dataset1.label = "Today Schedulled Trips";
        dataset1.backgroundColor = ['#51CFA0', '#2FC25B',];

        data.labels = ["Completed Today", "Active Today"];
        dataset1.data.push(this.state.data
            .filter(r => r.StartedAt.includes(this.props.date))
            .filter(r => r.EndedAt != null).length);
        dataset1.data.push(this.state.data
            .filter(r => r.StartedAt.includes(this.props.date))
            .filter(r => r.EndedAt == null).length);

        data.datasets.push(dataset1);
        return data;
    }

    getWeekData() {
        var data = {};

        data.labels = [];
        data.datasets = [];

        var dataset1 = {};
        dataset1.data = [];
        dataset1.label = "Trips Last 7 Days";
        dataset1.backgroundColor = '#0ECE9D';


        for (let index = 7; index >= 0; index--) {
            var date = moment(this.props.date, "YYYY-MM-DD").subtract(index, 'days');
            data.labels.push(date.format("MMM DD"));
            dataset1.data.push(this.state.data.filter(r => r.StartedAt.includes(date.format("YYYY-MM-DD"))).length);
        }


        data.datasets.push(dataset1);
        return data;
    }

    getMonthData() {
        var data = {};

        data.labels = [];
        data.datasets = [];

        var dataset1 = {};
        dataset1.data = [];
        dataset1.label = moment(this.props.date, "YYYY-MM-DD").format("MMMM YYYY") + " Trips";
        dataset1.backgroundColor = '#00AF50';


        for (let index = 1; index <= 31; index++) {
            data.labels.push(index.toString());
            var date = moment(this.props.date, "YYYY-MM-DD").format("YYYY-MM");
            date = (date + '-' + index);
            date = moment(date, "YYYY-MM-DD").format("YYYY-MM-DD");
            dataset1.data.push(this.state.data.filter(r => r.StartedAt.includes(date)).length);
        }


        data.datasets.push(dataset1);

        return data;
    }

    render() {


        return (
            <div>
                <Row gutter={20}>
                    <Card bordered={false} title={"Trips Today"}></Card>
                    <Col span={6}>
                        <Card>
                            <Statistic title="Today Trips" value={
                                this.state.data.filter(r => r.StartedAt.includes(this.props.date))
                                    .length
                            } prefix={<Icon type="flag"/>}/>
                            <a href="#" style={{marginTop: 16, marginLeft: 30}} type="primary"
                               onClick={() => setState("AppLayout", {page: "Trips"})}>More Info</a>
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card>
                            <Statistic title="Active Trips" value={
                                this.state.data.filter(r => r.StartedAt.includes(this.props.date))
                                    .filter(r => r.EndedAt == null)
                                    .length
                            } prefix={<Icon type="sync" spin/>}/>
                            <br/>
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card>
                            <Statistic title="Completed Trips To School" value={
                                this.state.data.filter(r => r.StartedAt.includes(this.props.date))
                                    .filter(r => r.EndedAt != null)
                                    .filter(r => get("schedules-map")[r.ScheduleId].Direction == "To School")
                                    .length
                            } prefix={<Icon type="shop"/>}/>
                            <br/>
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card>
                            <Statistic title="Completed Trips To Home" value={
                                this.state.data.filter(r => r.StartedAt.includes(this.props.date))
                                    .filter(r => r.EndedAt != null)
                                    .filter(r => get("schedules-map")[r.ScheduleId].Direction == "From School")
                                    .length
                            } prefix={<Icon type="home"/>}/>
                            <br/>
                        </Card>
                    </Col>
                </Row>
                <br/> <br/>
                <Row gutter={10}>
                    <Col span={12} style={{marginLeft: -100}}>
                        <Doughnut data={this.getPieData()}/>
                    </Col>
                    <Col span={13} style={{marginLeft: -50}}>

                        <Tabs defaultActiveKey="1">
                            <Tabs.TabPane TabPane tab="Bar" key="2"> <Bar data={this.getWeekData()}/> </Tabs.TabPane>
                            <Tabs.TabPane TabPane tab="Line" key="3"> <Line data={this.getWeekData()}/> </Tabs.TabPane>
                        </Tabs>
                    </Col>
                </Row>
                <br/> <br/> <br/>

                <Row gutter={20}>
                    <Card bordered={false}
                          title={moment(this.props.date, "YYYY-MM-DD").format("MMMM YYYY") + " Summary"}></Card>
                    <Col span={6}>
                        <Card>
                            <Statistic title="Total Trips" value={
                                this.state.data.length
                            } prefix={<Icon type="flag"/>}/>
                            <a href="#" style={{marginTop: 16, marginLeft: 30}} type="primary"
                               onClick={() => setState("AppLayout", {page: "Trips"})}>More Info</a>
                        </Card>
                    </Col>

                    <Col span={6}>
                        <Card>
                            <Statistic title="Completed Trips To School" value={
                                this.state.data
                                    .filter(r => r.EndedAt != null)
                                    .filter(r => get("schedules-map")[r.ScheduleId].Direction == "To School")
                                    .length
                            } prefix={<Icon type="shop"/>}/>
                            <br/>
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card>
                            <Statistic title="Completed Trips To Home" value={
                                this.state.data
                                    .filter(r => r.EndedAt != null)
                                    .filter(r => get("schedules-map")[r.ScheduleId].Direction == "From School")
                                    .length} prefix={<Icon type="home"/>}/>
                            <br/>
                        </Card>
                    </Col>
                </Row>
                <br/>
                <br/>
                <Row gutter={10}>
                    <Col span={24} style={{marginLeft: -20}}>
                        <Card bordered={false}>
                            <Tabs defaultActiveKey="1">
                                <Tabs.TabPane TabPane tab="Bar" key="2">
                                    <Bar height={70} data={this.getMonthData()}/>
                                </Tabs.TabPane>
                                <Tabs.TabPane TabPane tab="Line" key="3">
                                    <Line height={70} data={this.getMonthData()}/>
                                </Tabs.TabPane>
                            </Tabs>
                        </Card>
                    </Col>
                </Row>
                <br/>
            </div>)
    }
}

export default TripsStats;