import React, {Component} from 'react';
import {setState, subscribe, getState, getInstance} from '../../utils/bunifu-redux';
import Bootsrap from './BootSrap';
import { Row, Col, Menu, Divider, Layout, Icon, PageHeader, Button, Tag, message, Radio, Popconfirm, Card } from 'antd';  
import { get, remove, put } from '../../utils/Storage';
import JSONFormatter from 'json-formatter-js'
import copy from 'copy-to-clipboard';
import Search from 'antd/lib/input/Search';
class StorageExplorer extends Component {
state = {
   loading: false,
   selItem: null,
   items: [], 
   page: 0,
   menu: true,
   search: ''
}
 componentWillMount() { 
   var items = Object.keys(localStorage).sort();
   this.setState({
         items: items,
         selItem: items.length>0?items[0]:null
   });
   
    
 }
 componentDidMount() {
   this.componentDidUpdate();
 }
 componentDidUpdate() {
  var myJson = get(this.state.selItem);   
  const formatter = new JSONFormatter(myJson,1,{
    hoverPreviewEnabled: false,
    hoverPreviewArrayCount: 100,
    hoverPreviewFieldCount: 5,
    theme: '',
    animateOpen: true,
    animateClose: true,
    useToJSON: true
  });  
    document.getElementById('json').innerHTML="";
    document.getElementById('json').appendChild(formatter.render()); 
 }
 
 render() {
   
  var myJson = get(this.state.selItem);  

return (  <Layout className="layout" style={{backgroundColor: 'white'}}>
  
    <Layout.Header style={{backgroundColor: '#00AF50' }}>
       <font style={{color: 'white',marginLeft: -10}} size = {4}><b>Moing</b> | Data Browser</font>
    </Layout.Header>
     <Row gutter={1}> 
       <Col span={3}  hidden={!this.state.menu} >
      
       <Menu
        onSelect={(val)=>{
           this.setState({
               selItem: val.key 
           });
        }}
        style={{ width: '100%' }}
        defaultSelectedKeys={[this.state.selItem]} 
        mode="inline"
        //theme='dark'
      >
  <Search
      placeholder="input search text"
      onChange={value => this.setState({search: value.target.value})}
      style={{ width: '94%',marginTop: 5,marginLeft: 5 }}
    />
           
      {this.state.items.filter(r=>r.toLowerCase().includes(this.state.search)).map(r=>{
          return <Menu.Item key={r}>{r}</Menu.Item> 
         
      })}
         
         
      </Menu>
       </Col>
       <Col span={this.state.menu ? 21: 24}>
       
    <PageHeader
     style={{
      border: '1px solid rgb(235, 237, 240)',
      borderStyle: 'none none solid none'
      
    }}
         onBack={()=>this.setState({menu: !this.state.menu})}
      title={this.state.selItem}
      backIcon={<Icon type={this.state.menu? "menu-fold": "menu-unfold" } />}
      // tags={<Tag>Running</Tag>}
      subTitle={ JSON.stringify(myJson).length + " characters"}
      extra={[
        
        <Popconfirm onConfirm={()=>{
            //reset herer
              put(this.state.selItem,"");
              message.success("Successfully reset.");
              this.forceUpdate();
        }} title="Are you sure？" okText="Yes" cancelText="No">
          <Button shape="round" key="2">Reset&nbsp;{this.state.selItem}</Button>
        </Popconfirm>, 
        <Popconfirm onConfirm={()=>{
          //reset herer
            remove(this.state.selItem);
            message.success("Successfully deleted.");
           this.componentWillMount();
      }} title="Are you sure？" okText="Yes" cancelText="No">
        <Button shape="round" key="2">Delete&nbsp;{this.state.selItem}</Button>
      </Popconfirm>, 
        <Button shape="round" key="1" icon='beer' type="primary" onClick={()=>{
           var text = JSON.stringify(get(this.state.selItem));
           copy(text);
            message.success("Copied to clipboard");
        }}>
           Copy to Clipboard
        </Button>,
      ]}
    >
      
    </PageHeader>
    <br/>
     <Card bordered={false}>
       <Radio.Group  buttonStyle="solid" value={this.state.page} onChange={(e)=>this.setState({page: e.target.value})}>
          <Radio.Button value={0}>Formatted</Radio.Button>
          <Radio.Button value={1}>Raw</Radio.Button> 
        </Radio.Group>
        <br/>
        <br/>
        <br/>
       <Row hidden={this.state.page===0} style={{align: 'center' }} type="flex">
          {JSON.stringify(myJson)}
      </Row>
      <Row hidden={this.state.page===1} type="flex">
           <div style={{width: '100%'}} id="json"></div>
      </Row>
      </Card>
       </Col>
     </Row>
     
</Layout>)
}
}
export default StorageExplorer;