import React, {Component} from 'react';
import {subscribe} from '../../utils/bunifu-redux';
import {
    Avatar,
    Button,
    Card,
    Checkbox,
    Col,
    DatePicker,
    Icon,
    Input,
    Layout,
    message,
    Modal,
    Popconfirm,
    Radio,
    Row,
    Select,
    Skeleton,
    Table,
    Tabs,
    Tag,
    Tooltip
} from 'antd';
import axios from 'axios';

import moment from 'moment';
import {get, keyValue, linq, put} from "../../utils/Storage";
import Message from './Message';
import auth from "../../Components/Auth";

class Guardians extends Component {
    state = {
        name: 'Guardians',
        current: null,
        students: get("students"),
        stationMap: get("stations-map"),
        routeMap: get("routes-map"),
        page: 'Active',
        searchText: "",
        selClasses: [],
        selStreams: [],
        edit: -2,
        messages: [],
        sendMessage: false,
        sync: {
            loading: false,
            error: null,
            time: null
        },
        columns: [
            {
                title: '',
                dataIndex: 'id',
                key: 'name',
                render: (val) => <Avatar style={{backgroundColor: window.getColor(val + " null", 50, 50)}}> <Icon
                    type='user'/></Avatar>

            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                render: (val, obj, idx) => (obj.id === this.state.edit ?
                    <span>
                                <Input
                                    onPressEnter={() => {
                                        this.update(obj);
                                        this.setState({edit: -2})
                                    }}
                                    onChange={(e) => {
                                        idx = this.state.data.findIndex(r => r.id === obj.id);
                                        var val = e.target.value;
                                        var newState = this.state.data;
                                        newState[idx].name = val;
                                        this.setState({data: newState})

                                    }}
                                    value={val}/>
                            </span>
                    :
                    <span onDoubleClick={() => this.setState({edit: obj.id})}>{window.TitleCase(val)}</span>)


            }, {

                title: 'Phone',
                dataIndex: 'phone',
                key: 'phone',
                render: (val, obj, idx) => (obj.id === this.state.edit ?
                    <span>
                            <Input

                                onPressEnter={() => {
                                    this.update(obj);
                                    this.setState({edit: -2})
                                }}
                                onChange={(e) => {
                                    idx = this.state.data.findIndex(r => r.id === obj.id);
                                    var val = e.target.value;
                                    var newState = this.state.data;
                                    newState[idx].phone = val;
                                    this.setState({data: newState})
                                }}
                                value={val}/>
                        </span>
                    :
                    <span onDoubleClick={() => this.setState({edit: obj.id})}>{val}</span>)
            }, {

                title: 'Email',
                dataIndex: 'email',
                key: 'email',
                render: (val, obj, idx) => (obj.id === this.state.edit ?
                    <span>
                            <Input
                                onPressEnter={() => {
                                    this.update(obj);
                                    this.setState({edit: -2})
                                }}
                                onChange={(e) => {
                                    idx = this.state.data.findIndex(r => r.id === obj.id);
                                    var val = e.target.value;
                                    var newState = this.state.data;
                                    newState[idx].email = val;
                                    this.setState({data: newState})
                                }}
                                value={val}/>
                        </span>
                    :
                    <span onDoubleClick={() => this.setState({edit: obj.id})}>{val}</span>)
            },
            {
                title: 'SMS Notifications',
                dataIndex: 'Sms',
                render: (Sms, obj, idx) => <Checkbox checked={Sms === true} onChange={
                    (chk) => {
                        idx = this.state.data.findIndex(r => r.id === obj.id);
                        var newState = this.state.data;
                        newState[idx].Sms = chk.target.checked;
                        //update the data here
                        this.update(obj);
                        this.forceUpdate();
                    }
                }/>
            }
            ,
            {
                title: 'Students',
                dataIndex: 'id',
                key: 'students',
                render: id => (
                    <span>
                         <Tag
                             color="green">{this.state.students.filter(r => r.guardians.filter(rr => rr && rr.id === id).length > 0).length}</Tag>
                      </span>
                )
            },
            {
                title: '',
                dataIndex: 'id',
                key: 'id',
                render: (id, obj) => <span>
                    <Icon type='message'
                          onClick={() => {
                              this.setState({
                                  current: obj
                              });
                          }}
                    />
                    &nbsp;&nbsp;
                    <Icon
                        size=""
                        hidden={this.state.edit !== id}
                        type="check"
                        style={{
                            color: "green",
                        }}
                        onClick={
                            () => {
                                this.update(obj);
                                this.setState({
                                    edit: -2
                                });
                            }
                        }
                    />
                    &nbsp;&nbsp;
                    <Icon

                        hidden={!(auth.Update(auth.Guardians) && this.state.edit < -1)}
                        size=""
                        type="edit"
                        style={{
                            color: "green"
                        }}
                        onClick={
                            () => {

                                this.setState({
                                    edit: id
                                });
                            }
                        }
                    />
                    <Popconfirm placement="left" title={"Delete Guardian?"} onConfirm={() => {
                        axios.delete('/guardian/' + id)
                            .then(response => {
                                message.success("Successfully deleted.");
                                this.LoadData();
                            })
                            .catch(error => {
                                message.error(error['message']);
                            });
                    }} okText="Yes" cancelText="No">
                    <Icon hidden={auth.noDelete(auth.Guardians)}
                          style={{color: 'red', marginLeft: 20}} type="close"/>
                   </Popconfirm>
                   </span>
            }],
        data: []
    }

    componentWillMount() {
        subscribe(this);
        this.LoadData();

    }

    componentDidMount() {
        // set timeout of 3 secs
        setTimeout(() => {
                this.setState({
                    data: get("guardians")
                });
            }
            , 500);

        // add a timer to load data every 5 mins
        setInterval(() => {
                this.LoadData();
            }
            , 300000);
    }


    LoadData() {
        this.setState({sync: {loading: true}});
        axios.get('/guardian')
            .then(function (response) {
                put("guardians", response.data);
                put("guardians-map", keyValue(response.data));
                this.setState({
                    data: response.data,
                    sync: {
                        loading: false,
                        error: null,
                        time: moment()
                    },
                })
            }.bind(this))
            .catch(function (error) {
                message.error(error['message']);
                this.setState(
                    {
                        sync: {
                            loading: false,
                            error: error["message"],
                        }
                    })
            }.bind(this));
    }

    update(obj) {
        obj.roles = [];
        obj.password = "";
        obj.providerid = null;

        if (obj.id >= 0) {
            //patch
            axios.patch('/user', obj)
                .then(function (response) {
                    put("guardians", this.state.data);
                    put("guardians-map", keyValue(this.state.data));
                    message.success("Saved");
                }.bind(this))
                .catch(function (error) {
                    message.error(error.message);
                });
        } else {
            //post
            axios.post('/user', obj)
                .then(function (response) {
                    var c = this.state.data;
                    c.unshift(response.data);
                    this.setState({
                        data: c,
                        edit: response.data.id
                    });
                    message.success("Added");
                }.bind(this))
                .catch(function (error) {
                    message.error(error.message);
                });
        }
    }

    render() {

        const _VEHICLES = linq("vehicles");
        const _USERS = linq("users");

        let dataFiltered = [];

        for (let index = 0; index < this.state.data.length; index++) {
            let parent = this.state.data[index];
            let match = true;


            if (this.state.searchText.length > 0 && !(parent.name.toString().toLowerCase().includes(this.state.searchText.toString().toLowerCase().trim()) || parent.phone.toString().toLowerCase().includes(this.state.searchText.toString().toLowerCase().trim()))) {
                match = false;
            }
            //filter classes
            if (this.state.selClasses.length > 0) {
                var cm = false;
                parent.students.forEach(s => {
                    if (this.state.selClasses.includes(s.Class)) {
                        cm = true;
                    }
                });
                if (!cm) {
                    match = false;
                }
            }
            //   //filter streams
            if (this.state.selStreams.length > 0) {
                var cm = false;
                parent.students.forEach(s => {
                    if (this.state.selStreams.includes(s.Stream)) {
                        cm = true;
                    }
                });
                if (!cm) {
                    match = false;
                }
            }
            if (match) {
                dataFiltered.push(parent);
            }
        }

        const classes = [];
        for (let i = 0; i < get("classes").length; i++) {
            classes.push(<Select.Option key={get("classes")[i]}>{get("classes")[i]}</Select.Option>);
        }
        const streams = [];
        for (let i = 0; i < get("streams").length; i++) {
            streams.push(<Select.Option key={get("streams")[i]}>{get("streams")[i]}</Select.Option>);
        }


        var syncTitle = "Syncing... Please wait";
        var lastSync = "";


        if (this.state.sync.time != null) {
            lastSync = moment(this.state.sync.time).fromNow();
            syncTitle = "Successfully synced " + lastSync;
        } else if (this.state.sync.error != null) {
            syncTitle = "Failed to sync: " + this.state.sync.error;
            lastSync = "";
        }

        // if sync is in progress
        if (this.state.sync.loading) {
            lastSync = "Syncing Data...";
        }

        const operations = <span>
         <Tooltip onVisibleChange={() => this.forceUpdate()} placement="topLeft" title={syncTitle}>
            <Button
                loading={this.state.sync.loading}
                type={this.state.sync.error == null ? (this.state.sync.loading ? "dashed" : "primary") : "danger"}
                onClick={() => this.LoadData()}
                shape="round"
                icon="sync"
                size={"small"}
            >
            {lastSync}
            </Button>
        </Tooltip>
            &nbsp;
            <Button hidden={auth.noView(auth.CanSendSMS)} disabled={dataFiltered.length == 0}
                    onClick={() => {
                        var arr = [];
                        dataFiltered.forEach(guardian => {
                            var obj = {};
                            obj["Name"] = guardian.name;
                            obj["Phone"] = guardian.phone;
                            obj["Email"] = guardian.email;
                            arr.push(obj);
                        });
                        this.setState({messages: arr, sendMessage: true});
                    }} type="normal" shape="round" icon="message" size={"small"}>Send Message</Button> &nbsp;
            <Button
                onClick={() => {
                    var c = this.state.data;
                    c.unshift({
                        id: -1,
                        name: "",
                        phone: '000000000',
                        email: "",
                        students: []
                    });
                    this.setState({
                        data: c
                    });
                }}
                type="primary" hidden shape="round" icon="plus" size="small">Add Guardian</Button></span>;

        var data_active = dataFiltered.filter(r => this.state.students.filter(rr => rr.schedules.length > 0 && rr.guardians.filter(rrr => r && rrr && rrr.id === r.id).length > 0).length > 0);
        var data_inactive = dataFiltered.filter(r => this.state.students.filter(rr => rr.schedules.length === 0 && rr.guardians.filter(rrr => r && rrr && rrr.id === r.id).length > 0).length > 0);


        if (this.state.page === 'Active') {
            dataFiltered = data_active;
        } else if (this.state.page === 'Inactive') {
            dataFiltered = data_inactive;
        }


        const expandedRowRender2 = (row) => {


            var schedules = row.schedules;

            const columns = [
                {
                    title: 'Name',
                    dataIndex: 'Name',
                    key: 'Name',
                    render: (val, schedule) => <Tag size='small'
                                                    color={schedule.Direction === "To School" ? "#00AF50" : "gray"}><Icon
                        type={schedule.Direction === "To School" ? "arrow-down" : "arrow-up"}/> {val}</Tag>
                },
                {title: 'Direction', dataIndex: 'Direction', key: 'Direction'},
                {
                    title: 'Vehicle',
                    dataIndex: 'VehicleId',
                    key: 'VehicleId',
                    render: (id) => {
                        try {
                            return <Tag>{_VEHICLES.firstOrDefault(v => v.id === id).PlateNo}</Tag>
                        } catch (err) {
                            return <Skeleton/>
                        }
                    }
                },
                {
                    title: 'Driver',
                    dataIndex: 'DriverId',
                    key: 'DriverId',
                    render: (val) => <Tag>{get("users-map")[val].name || "⚠️"}</Tag>
                },
                {
                    title: 'Agent',
                    dataIndex: 'AttendantId',
                    key: 'AttendantId',
                    render: (val) => <Tag>{get("users-map")[val].name || "⚠️"}</Tag>
                },


            ];

            return <Table columns={columns} dataSource={schedules} pagination={false}/>;
        };


        const expandedRowRender = (g) => {

            const columns = [
                {title: 'Adm', dataIndex: 'Adm', key: 'Adm'},
                {title: 'Name', dataIndex: 'Name', key: 'Name'},
                {title: 'Class', dataIndex: 'Class', key: 'Class'},
                {title: 'Stream', dataIndex: 'Stream', key: 'Stream'},
                {
                    title: 'Route', dataIndex: 'RouteId', key: 'RouteId',
                    render: (id) => <Tag>{this.state.routeMap[id].Name}</Tag>
                },
                {
                    title: 'Station', dataIndex: 'StationId', key: 'StationId',
                    render: (id) => <Tag color="green">Publi{this.state.stationMap[id].Name}sh</Tag>
                },
                {
                    title: 'Schedules',
                    dataIndex: 'id',
                    render: (text, obj) => <Tag
                        color={obj.schedules.filter(r => r.Year === window.year).length > 0 ? 'green' : null}>{obj.schedules.filter(r => r.Year === window.year).length}</Tag>
                }

            ];

            const data = this.state.students.filter(r => r.guardians.filter(rr => rr.id == g.id).length > 0);

            return <Table columns={columns} dataSource={data} expandedRowRender={expandedRowRender2}
                          pagination={false}/>;
        };


        return (

            <Layout.Content>
                <Message onClose={() => this.setState({sendMessage: false})} visible={this.state.sendMessage}
                         data={this.state.messages}/>
                <Modal destroyOnClose={true} footer={[]} maskClosable={false} width={1000}
                       onCancel={() => this.setState({current: null})} title={"Message Log (SMS)"}
                       visible={this.state.current !== null}><MessageLog data={this.state.current}/></Modal>
                <Card bordered={false} loading={this.state.data.length === 0}>

                    <Tabs defaultActiveKey="1" tabBarExtraContent={operations}>
                        <Tabs.TabPane tab={<span><Icon type="team"/>Guardians ({dataFiltered.length})</span>} key="1">
                        </Tabs.TabPane>
                    </Tabs>

                    <Radio.Group value={this.state.page} buttonStyle="solid"
                                 onChange={(e) => this.setState({page: e.target.value})}>
                        <Radio.Button value="All">All ({dataFiltered.length})</Radio.Button>
                        <Radio.Button value="Active">Using Transportation ({data_active.length})</Radio.Button>
                        <Radio.Button value="Inactive">Not Using Transportation({data_inactive.length})</Radio.Button>
                    </Radio.Group>
                    <br/> <br/> <br/>
                    <Row gutter={5}>
                        <Col span={5}>
                            <Input.Search
                                placeholder="Search Guardian"
                                value={this.state.searchText}
                                onChange={(e) => this.setState({searchText: e.target.value})}
                                onSearch={(value) => this.setState({searchText: value})}
                                style={{width: '100%'}}

                            />


                        </Col>

                        <Col span={5}>
                            <Select
                                mode="multiple"
                                style={{width: '100%'}}
                                placeholder="Filter Classes "
                                onChange={(val) => {
                                    if (val.toString().trim().length > 0) {
                                        this.setState({selClasses: val.toString().split(",")});
                                    } else {
                                        this.setState({selClasses: []});
                                    }

                                }}
                            >
                                {classes}
                            </Select>
                        </Col>
                        <Col span={8}>
                            <Select
                                mode="multiple"
                                style={{width: '100%'}}
                                placeholder="Filter Streams "
                                onChange={(val) => {
                                    if (val.toString().trim().length > 0) {
                                        this.setState({selStreams: val.toString().split(",")});
                                    } else {
                                        this.setState({selStreams: []});
                                    }

                                }}
                            >
                                {streams}
                            </Select>
                        </Col>
                        <Col>


                        </Col>
                        <br/>
                        <br/>
                        <br/>
                        <Col span={24}>
                            <Table
                                className="components-table-demo-nested"
                                expandedRowRender={expandedRowRender}
                                dataSource={dataFiltered}
                                columns={this.state.columns}
                            />
                        </Col>
                    </Row>
                </Card>
            </Layout.Content>
        )
    }
}

class MessageLog extends Component {
    state = {
        date: moment(),
        loading: true,
        dataSource: []

    }

    componentWillMount() {
        this.LoadData();


    }

    componentDidMount() {


    }


    LoadData() {
        this.setState({
            loading: true,
            dataSource: []
        });
        axios.get('https://api.app.bunifu.io/api/sms/messagesByPhone?phone=' + this.props.data.phone + '&date=' + this.state.date.format('YYYY-MM-DD'))
            .then((response) => {
                var ret = [];
                response.data.forEach(element => {
                    ret.push({
                        message: element.message,
                        time: moment(element.updated_at),
                        sent: (element.statuses.filter(r => r.event === 'SENT').length > 0)
                    });
                });
                this.setState({
                    loading: false,
                    dataSource: ret
                });
            })
            .catch((error) => {
                message.error(error['message']);
                this.setState({
                    loading: false
                });
            });
    }

    render() {
        return (<div>
            <DatePicker allowClear={false} value={this.state.date} onChange={(val) => {
                this.state.date = val;
                this.LoadData();
            }} placeholder="Select date"/> &nbsp;
            <Icon type='sync' onClick={() => this.LoadData()}/>
            <br/>
            <Table size={"small"} loading={this.state.loading} columns={
                [
                    {
                        title: '',
                        dataIndex: 'message',
                        render: (message, obj) => <Tag><Icon type={obj.sent ? 'check' : 'stop'}
                                                             style={{color: obj.sent ? 'green' : 'crimson'}}/>&nbsp;&nbsp;&nbsp;
                            <b>{obj.time.format('LT')}:</b> {message} </Tag>

                    }
                ]
            } dataSource={this.state.dataSource}/>

        </div>)
    }
}

export default Guardians;