import React, { Component } from "react";
import {
  Typography,
  Button,
  Card,
  Col,
  Form,
  Icon,
  Input,
  Layout,
  message,
  Popconfirm,
  Row,
  Select,
  Switch,
  Checkbox,
  Modal,
  Menu,
} from "antd";
import { get } from "../../../utils/Storage";
import axios from "axios";
import { Params, Route } from "../../../utils/Routing";
import auth from "../../../Components/Auth";

const { Text } = Typography;
const { Sider, Content } = Layout;

class ManageUser extends Component {
  state = {
    id: -1,
    roles: [],
    modules: [],
    rolePermissions: {},
    name: null,
    phone: null,
    email: null,
    payload: "",
    providerid: null,
    loading: false,
    sms: 1,
    visible: false,
    confirmLoading: false,
    selectedRole: null,
  };
  constructor(props) {
    super(props);

    this.getModules = this.getModules.bind(this);
    this.confirm = this.confirm.bind(this);
    this.savePermissions = this.savePermissions.bind(this);
    this.save = this.Save.bind(this);
    this.showModal = this.showModal.bind(this);
    this.handleOk = this.handleOk.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.setPermissions = this.setPermissions.bind(this);
    this.handlePermissionChange = this.handlePermissionChange.bind(this);
    this.handleSelectAllChange = this.handleSelectAllChange.bind(this);
    this.handleRoleChange = this.handleRoleChange.bind(this);
  }
  componentDidMount() {
    var params = Params();
    var user = {};

    if (params.id) {
      var id = params.id;
      user = get("users-map")[id];
      if (user == null) {
        message.error("The record does not exist, Redirecting...");
        setTimeout(() => {
          Route("users");
        }, 3000);
        return;
      }

      if (get("admin").role == "Transporter") {
        var pid = get("admin").user.providerid;
        user.providerid = pid;
      }

      this.setState({
        id: user.id,
        name: user.name,
        email: user.email,
        phone: user.phone,
        password: user.password,
        providerid: user.providerid ? Number(user.providerid) : null,
        roles: user.roles,
        payload: user.payload,
        sms: this.state.sms,
      });

      this.getModules(user.id);
    } else {
      if (get("admin").role == "Transporter") {
        var pid = Number(get("admin").user.providerid);
        this.setState({ providerid: pid });
      }
      this.getModules(null);
    }
  }

  getModules(userId) {
    axios
      .get("/permissions/modules")
      .then((response) => {
        if (userId) {
          //Populate permissions
          this.setPermissions(userId);
        }

        this.setState({ modules: response.data });
      })
      .catch((error) => {
        message.error(error["message"]);
      });
  }

  confirm() {
    message.loading("Please wait...", 0);
    axios
      .delete("/user/" + this.state.id)
      .then((response) => {
        message.destroy();
        message.success("Successfully deleted.");
        Route("users");
      })
      .catch((error) => {
        message.destroy();
        message.error(error["message"]);
      });
  }

  savePermissions(userId) {
    const { rolePermissions } = this.state;

    const promises = [];

    // Loop through all roles in rolePermissions
    Object.keys(rolePermissions).forEach((roleId) => {
      // Loop through all modules for each role
      Object.keys(rolePermissions[roleId]).forEach((moduleId) => {
        const modulePermissions = rolePermissions[roleId][moduleId];
        const queryParams = new URLSearchParams({
          module_id: moduleId,
          role_id: roleId,
          user_id: userId,
          can_view: modulePermissions.can_view || 0,
          can_add: modulePermissions.can_add || 0,
          can_modify: modulePermissions.can_modify || 0,
          can_delete: modulePermissions.can_delete || 0,
        }).toString();

        // Push each axios.post promise to the promises array
        promises.push(axios.patch(`/permissions/?${queryParams}`));
      });
    });

    this.setState({ confirmLoading: true });

    // Execute all promises
    Promise.all(promises)
      .then(() => {
        message.success("Permissions saved successfully!");
        this.setState({ visible: false, confirmLoading: false });

        Route("users");
      })
      .catch((error) => {
        message.error("Failed to save permissions!");
        console.error(error);
        this.setState({ confirmLoading: false });
      });
  }

  Save(e) {
    e.preventDefault();

    var obj = {
      id: this.state.id,
      name: this.state.name,
      phone: this.state.phone,
      password: this.state.password,
      email: this.state.email,
      roles: this.state.roles,
      providerid: this.state.providerid,
      payload: this.state.payload,
    };

    message.loading("Please wait...", 0);
    if (obj.id >= 0) {
      axios
        .patch("/user", obj)
        .then((response) => {
          message.destroy();
          message.success("Saved");

          this.savePermissions(obj.id);
          //Route("users");
        })
        .catch((error) => {
          message.destroy();
          message.error(error.message);
        });
    } else {
      //post
      axios
        .post("/user", obj)
        .then((response) => {
          message.success("Added");

          //Save Permissions
          this.savePermissions(response.data.id);

          //Route("users");
        })
        .catch((error) => {
          message.error(error.message);
        });
    }
  }

  showModal() {
    const { roles } = this.state;
    this.setState({
      visible: true,
      selectedRole: roles.length > 0 ? roles[0].id : null,
    });
  }

  handleOk() {
    this.setState({
      confirmLoading: true,
    });
    setTimeout(() => {
      this.setState({
        visible: false,
        confirmLoading: false,
      });
    }, 2000);
  }

  handleCancel() {
    this.setState({
      visible: false,
    });
  }

  setPermissions(userId) {
    axios
      .get("/permissions/user/" + userId)
      .then((response) => {
        const permissionsData = response.data;
        const rolePermissions = {};

        permissionsData.forEach((module) => {
          const modulePermissions = module.permissions.filter(
            (f) => f.user_id == userId
          )[0];
          modulePermissions.roles.forEach((role) => {
            if (!rolePermissions[role.id]) {
              rolePermissions[role.id] = {};
            }
            rolePermissions[role.id][module.id] = {
              can_view: modulePermissions.can_view,
              can_add: modulePermissions.can_add,
              can_modify: modulePermissions.can_modify,
              can_delete: modulePermissions.can_delete,
            };
          });
        });
        this.setState({ rolePermissions });
      })
      .catch((error) => {
        message.error(error["message"]);
      });
  }

  handlePermissionChange(moduleId, permissionType, checked) {
    const { selectedRole } = this.state;
    if (!selectedRole) return;

    this.setState((prevState) => {
      const updatedRolePermissions = { ...prevState.rolePermissions };
      if (!updatedRolePermissions[selectedRole]) {
        updatedRolePermissions[selectedRole] = {};
      }
      if (!updatedRolePermissions[selectedRole][moduleId]) {
        updatedRolePermissions[selectedRole][moduleId] = {};
      }
      updatedRolePermissions[selectedRole][moduleId][permissionType] = checked
        ? 1
        : 0;

      console.log(updatedRolePermissions);

      return { rolePermissions: updatedRolePermissions };
    });
  }

  handleSelectAllChange(moduleId, checked) {
    const { selectedRole } = this.state;
    if (!selectedRole) return;

    this.setState((prevState) => {
      const updatedRolePermissions = { ...prevState.rolePermissions };
      if (!updatedRolePermissions[selectedRole]) {
        updatedRolePermissions[selectedRole] = {};
      }
      updatedRolePermissions[selectedRole][moduleId] = {
        can_view: checked ? 1 : 0,
        can_add: checked ? 1 : 0,
        can_modify: checked ? 1 : 0,
        can_delete: checked ? 1 : 0,
      };
      return { rolePermissions: updatedRolePermissions };
    });
  }

  handleRoleChange(roleId) {
    this.setState({ selectedRole: roleId, visible: true });
  }

  render() {
    var roles = get("roles");
    var params = Params();
    var id = params.id;

    var providers = get("providers");

    var me = get("admin").user.id == id;

    const {
      modules,
      rolePermissions,
      visible,
      confirmLoading,
      ModalText,
      selectedRole,
    } = this.state;
    const permissions = rolePermissions[selectedRole] || {};

    return (
      <div>
        <Layout.Content>
          <Row style={{ textAlign: "center" }}>
            <Col span={12} style={{ textAlign: "left", marginLeft: "25%" }}>
              <Card>
                <Row>
                  <Col span={11} style={{ textAlign: "center" }}>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <img src="../img/driver.jpg" width="95%" />
                  </Col>
                  <Col span={12}>
                    <Col span={24} style={{ textAlign: "center" }}>
                      <br />
                      {id ? (
                        <h2>EDIT SYSTEM USER</h2>
                      ) : (
                        <h2>ADD SYSTEM USER</h2>
                      )}
                    </Col>{" "}
                    <br />
                    <br />
                    <br /> <br />
                    <Form className="login-form">
                      <Form.Item>
                        <Select
                          disabled={me}
                          mode="multiple"
                          style={{ width: "100%" }}
                          placeholder="Select Roles"
                          value={this.state.roles.map((r) => r.id)}
                          onChange={(val) => {
                            if (val.toString().trim().length > 0) {
                              var ids = val.toString().split(",");
                              var arr = [];

                              ids.forEach((id) =>
                                arr.push(roles.filter((r) => r.id == id)[0])
                              );

                              this.setState({ roles: arr }, this.showModal);
                            } else {
                              this.setState({ roles: [], selectedRole: null });
                            }
                            //Populate selected roles
                            // const selectedRoles = val.map((id) => roles.find((r) => r.id === id));
                            // this.setState({ roles: selectedRoles }, this.showModal);
                          }}
                        >
                          {roles.map((r) => {
                            return (
                              <Select.Option
                                disabled={
                                  get("admin").role == "Transporter" &&
                                  r.name == "Administrator"
                                }
                                key={r.id}
                                value={r.id}
                              >
                                {r.name}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                      {this.state.roles.length > 0 && (
                        <Button
                          type="primary"
                          onClick={this.showModal}
                          style={{ marginBottom: "24px", width: "100%" }}
                        >
                          Edit User Permissions
                        </Button>
                      )}

                      <Modal
                        title="User Permissions"
                        visible={visible}
                        onOk={this.handleOk}
                        confirmLoading={confirmLoading}
                        onCancel={this.handleCancel}
                        width={1350}
                      >
                        <Layout>
                          <Sider width={200} className="site-layout-background">
                            <Menu
                              mode="inline"
                              selectedKeys={[`${selectedRole}`]}
                              style={{ height: "100%", borderRight: 0 }}
                              onSelect={({ key }) =>
                                this.setState({ selectedRole: parseInt(key) })
                              }
                            >
                              {this.state.roles.map((role) => (
                                <Menu.Item key={role.id}>{role.name}</Menu.Item>
                              ))}
                            </Menu>
                          </Sider>
                          <Layout>
                            <Content
                              className="site-layout-background"
                              style={{
                                padding: 24,
                                margin: 0,
                                // minHeight: 280,
                                height: "340px",
                                overflowY: "auto",
                              }}
                            >
                              {selectedRole &&
                                modules.map((m) => (
                                  <Col span={4} key={m.id}>
                                    <Card title={m.name} bordered={true}>
                                      <Checkbox
                                        style={{
                                          display: "flex",
                                          whiteSpace: "nowrap",
                                          alignItems: "center",
                                          flexDirection: "row",
                                          margin: "0px",
                                          marginBottom: "10px",
                                        }}
                                        checked={
                                          permissions[m.id]?.can_view === 1 &&
                                          permissions[m.id]?.can_add === 1 &&
                                          permissions[m.id]?.can_modify === 1 &&
                                          permissions[m.id]?.can_delete === 1
                                        }
                                        onChange={(e) =>
                                          this.handleSelectAllChange(
                                            m.id,
                                            e.target.checked
                                          )
                                        }
                                      >
                                        <Text type="secondary">Select All</Text>
                                      </Checkbox>
                                      <Checkbox
                                        style={{
                                          display: "flex",
                                          whiteSpace: "nowrap",
                                          alignItems: "center",
                                          flexDirection: "row",
                                          margin: "0px",
                                        }}
                                        checked={
                                          permissions[m.id]?.can_view === 1
                                        }
                                        onChange={(e) =>
                                          this.handlePermissionChange(
                                            m.id,
                                            "can_view",
                                            e.target.checked
                                          )
                                        }
                                      >
                                        <Text type="secondary"> Can View </Text>
                                      </Checkbox>
                                      <Checkbox
                                        style={{
                                          display: "flex",
                                          whiteSpace: "nowrap",
                                          alignItems: "center",
                                          flexDirection: "row",
                                          margin: "0px",
                                        }}
                                        checked={
                                          permissions[m.id]?.can_add === 1
                                        }
                                        onChange={(e) =>
                                          this.handlePermissionChange(
                                            m.id,
                                            "can_add",
                                            e.target.checked
                                          )
                                        }
                                      >
                                        <Text type="secondary"> Can Add</Text>
                                      </Checkbox>
                                      <Checkbox
                                        style={{
                                          display: "flex",
                                          whiteSpace: "nowrap",
                                          alignItems: "center",
                                          flexDirection: "row",
                                          margin: "0px",
                                        }}
                                        checked={
                                          permissions[m.id]?.can_modify === 1
                                        }
                                        onChange={(e) =>
                                          this.handlePermissionChange(
                                            m.id,
                                            "can_modify",
                                            e.target.checked
                                          )
                                        }
                                      >
                                        <Text type="secondary">Can Modify</Text>
                                      </Checkbox>
                                      <Checkbox
                                        style={{
                                          display: "flex",
                                          whiteSpace: "nowrap",
                                          alignItems: "center",
                                          flexDirection: "row",
                                          margin: "0px",
                                        }}
                                        checked={
                                          permissions[m.id]?.can_delete === 1
                                        }
                                        onChange={(e) =>
                                          this.handlePermissionChange(
                                            m.id,
                                            "can_delete",
                                            e.target.checked
                                          )
                                        }
                                      >
                                        <Text type="secondary">
                                          {" "}
                                          Can Delete
                                        </Text>
                                      </Checkbox>
                                    </Card>
                                  </Col>
                                ))}
                            </Content>
                          </Layout>
                        </Layout>
                      </Modal>

                      {get("admin").role === "Administrator" && (
                        <Form.Item>
                          <Select
                            disabled={me}
                            style={{ width: "100%" }}
                            value={this.state.providerid}
                            onSelect={(val) =>
                              this.setState({ providerid: val })
                            }
                          >
                            <Select.Option value={null}>
                              No Provider
                            </Select.Option>
                            {providers.map((r) => {
                              return (
                                <Select.Option key={r.id} value={r.id}>
                                  {r.Name}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      )}
                      <Form.Item>
                        <Input
                          required
                          value={this.state.name}
                          onChange={(val) =>
                            this.setState({ name: val.target.value })
                          }
                          prefix={
                            <Icon
                              type="user"
                              style={{ color: "rgba(0,0,0,.25)" }}
                            />
                          }
                          placeholder="Name"
                        />
                      </Form.Item>
                      <Form.Item>
                        <Input
                          required
                          value={this.state.phone}
                          onChange={(val) =>
                            this.setState({ phone: val.target.value })
                          }
                          prefix={
                            <Icon
                              type="user"
                              style={{ color: "rgba(0,0,0,.25)" }}
                            />
                          }
                          placeholder="Phone"
                        />
                      </Form.Item>
                      <Form.Item>
                        <Input
                          required
                          type="email"
                          value={this.state.email}
                          onChange={(val) =>
                            this.setState({ email: val.target.value })
                          }
                          prefix={
                            <Icon
                              type="user"
                              style={{ color: "rgba(0,0,0,.25)" }}
                            />
                          }
                          placeholder="Email"
                        />
                      </Form.Item>
                      <Form.Item>
                        <Input
                          required
                          value={this.state.password}
                          onChange={(val) =>
                            this.setState({ password: val.target.value })
                          }
                          prefix={
                            <Icon
                              type="lock"
                              style={{ color: "rgba(0,0,0,.25)" }}
                            />
                          }
                          type="password"
                          placeholder="Password"
                        />
                      </Form.Item>
                      <Form.Item>
                        <Switch
                          value={this.state.sms === 1}
                          onChange={(val) =>
                            this.setState({ sms: val ? 1 : 0 })
                          }
                        />
                        <b> Receive SMS notifications.</b>
                      </Form.Item>
                      <Form.Item style={{ marginTop: -15 }}>
                        <Button
                          disabled={
                            this.state.id > 0
                              ? auth.noUpdate(auth.Users)
                              : auth.noInsert(auth.Users)
                          }
                          loading={this.state.loading}
                          onClick={this.Save.bind(this)}
                          type="primary"
                          style={{ width: "100%", marginTop: 10 }}
                          htmlType="submit"
                          className="login-form-button"
                        >
                          ✔ Save
                        </Button>
                        <Popconfirm
                          title="Are you sure to delete?"
                          onConfirm={this.confirm}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button
                            hidden={me}
                            disabled={auth.noDelete(auth.Users)}
                            style={{ width: "100%", marginTop: 10 }}
                            type="danger"
                            className="login-form-button"
                          >
                            X Delete
                          </Button>
                        </Popconfirm>
                        ,
                        <a href="#/users">
                          <Button
                            style={{ width: "100%", marginTop: 10 }}
                            type="secondary"
                            className="login-form-button"
                          >
                            Close
                          </Button>
                        </a>
                      </Form.Item>
                    </Form>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Layout.Content>
      </div>
    );
  }
}

export default ManageUser;
