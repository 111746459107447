export const dispatch = (route) => {
    //
    // if (!window._r_components)
    //     window._r_components = [];
    //
    // for (let i = 0; i < window._r_components; i++) {
    //     let _comp = window._r_components[i];
    //
    //     try {
    //         _comp.onRouteChange(route);
    //     } catch (e) {
    //
    //     }


    //}

    // for (let callBack in window._callbacks) {
    //     console.log(callBack)
    //     try {
    //         callBack.callBack(route).bind(this);
    //     } catch (e) {
    //         //  console.log(e.message)
    //     }
    //
    // }
}

export const subscribe = (classComponent) => {
    // if (!window._r_components)
    //     window._r_components = [];
    //
    //
    // window._r_components.push(classComponent);
    // classComponent.onRouteChange(Route());
}

export const listen = (callBack) => {
    if (!window._callbacks)
        window._callbacks = [];

    window._callbacks.push(
        {
            callBack: callBack,

        });

    //  console.log(window._callbacks)
}

function isNumeric(str) {
    if (str.startsWith("0")) return false;
    if (typeof str != "string") return false // we only process strings!
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

export function Param(name) {
    return Params()[name];
}

export function Params(property = undefined) {

    const ans = {};
    //http://localhost:3000/#/add-user?id=10&age=10
    const url = window.location.toString().trim();
    if (url.indexOf("?") > 0) {
        const search = url.substr(url.indexOf("?") + 1);
        const arr = search.split(`&`);
        arr.forEach(str => {
            if (str.indexOf("=") > 0) {
                const prop = str.substr(0, str.indexOf("="));
                let val = str.substr(str.indexOf("=") + 1).trim();
                if (isNumeric(val)) val = Number(val);
                ans[prop.trim()] = decodeURIComponent(val);
            } else {
                ans[str.trim()] = true;
            }
        });
    }

    if (property)
        return ans[property];

    return ans;
}

export function GoBack() {
    let callback = sessionStorage.getItem("callback");

    if (callback) {
        sessionStorage.removeItem("callback");
        //redirect to the callback
        Route(atob(callback));
        // force reload
        window.location.reload();
    }
}

export function CanGoBack() {
    let callback = sessionStorage.getItem("callback");
    return callback != null;
}

export function GetSubDomain() {
    const url = window.location.toString().trim();
    if (url.indexOf("://") > 0) {
        const sub = url.substr(0, url.indexOf("://"));
        const sub2 = url.substr(url.indexOf("://") + 3);
        if (sub2.indexOf(".") > 0) {
            return sub2.substr(0, sub2.indexOf("."));
        }
    }
    return null;
}

export const Route = (route = null) => {
    if (route != null) {

        if (!route.toString().includes("#"))
            route = "#/" + route;

        window.location.href = route;
    }
    const url = window.location.toString();
    if (url.indexOf("#/") >= 0) {
        let route = url.substr(url.indexOf("#") + 2).trim();
        if (route.indexOf("?") >= 0) {
            route = route.substr(0, route.indexOf("?"));
        }

        return route;
    }
    return null;
}

export function Anchor(url, target = null) {

    let callBack = Route();

    if (callBack && target == null)
        callBack = btoa(callBack);

    if (target != null)
        callBack = btoa(target);

    if (callBack)
        sessionStorage.setItem("callback", callBack);

    Route(url);
}

export function Redirect(url) {
    window.location.href = url;
    window.location.reload();
}


//How to load function after the page has loaded?
window.onhashchange = (e) => Redirect(e.newURL);


if (Route()) dispatch(Route());


// Add  a back button using session Storage.
export default {
    Route,
    Param,
    Params,
    RouteChanged: listen,
    GoBack,
    CanGoBack,
    Anchor,
    Redirect,
}