import React from 'react';
import _ from 'linq';

class GoSp extends React.Component {
    state = {}

    constructor(props) {
        super();
        this.state = {}
    }


    render() {

        let count = this.props.cols || this.props.size || this.props.s;
        if (!count) count = 2;


        return (
            <>
                {
                    _.range(0, count)
                        .select(() => <>&nbsp;</>)
                        .toArray()
                }
            </>
        )
    }
}

export default GoSp;