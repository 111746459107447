import React from 'react';
import Ant from "antd";
import _ from 'linq';
import moment from "moment/moment";

class Studentmessages extends React.Component {
    state = {}

    constructor(props) {
        super();
        this.state = {}
    }

    render() {
        return (
            <Ant.Row gutter={16}>
                <Ant.Col span={14}>
                    <Ant.DatePicker/>
                </Ant.Col>
                <Ant.Col span={10} align={"right"}>
                    <Ant.Input.Search placeholder="Search Messages..." onSearch={value => console.log(value)}
                                      enterButton/>

                </Ant.Col>
                <br/>
                <Ant.Divider dashed/>
                <div style={{overflow: "scroll", height: 500}}>
                    {
                        _.range(1, 5)
                            .select(com =>
                                <Ant.Row>
                                    <Ant.Col span={2}></Ant.Col>
                                    <Ant.Col span={20} style={{padding: 10}}>
                                        <Ant.Comment
                                            actions={[<span style={{float: "right"}}
                                                            key="comment-nested-reply-to">Delivered  SMS ✓✓</span>]}
                                            author={<Ant.Button type={"link"}>MEC School</Ant.Button>}
                                            className={"shadow"}
                                            style={{paddingLeft: 15, paddingRight: 15, borderRadius: 15}}
                                            avatar={
                                                <Ant.Avatar
                                                    className={"shadow"}
                                                    style={{backgroundColor: '#87d068'}}
                                                    icon={"message"}
                                                />
                                            }
                                            content={
                                                <p>
                                                    We supply a series of design principles, practical patterns and high
                                                    quality
                                                    design
                                                    resources (Sketch and Axure), to help people create their product
                                                    prototypes
                                                    beautifully
                                                    and efficiently.
                                                </p>
                                            }
                                            datetime={
                                                <Ant.Tooltip
                                                    title={moment().format('YYYY-MM-DD HH:mm:ss')}>
                                                    <span>{moment().fromNow()}</span>
                                                </Ant.Tooltip>

                                            }
                                        />
                                    </Ant.Col>
                                </Ant.Row>
                            )
                            .toArray()
                    }
                </div>


            </Ant.Row>
        )
    }
}

export default Studentmessages;