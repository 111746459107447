import React, {Component} from 'react';
import {setState, subscribe, getState, getInstance} from '../../utils/bunifu-redux';
import {Button, Drawer, Checkbox, Tag, Divider, Mention, Card, message } from 'antd';  
import { get, exists } from '../../utils/Storage';
import Axios from 'axios';
class Message extends Component {
state = {
   name: 'Message',
   message: "", 
   sms: true,
   app: true,
   email: true,
   loading: false
}
componentWillMount() {
  subscribe(this);
}

getTags() {
    if(this.props.data.length>0) {
        var arr = [];
        Object.keys(this.props.data[0]).forEach(e => {
            if(!(e.length>2 && e.toLowerCase().endsWith("id")) &&  !(e.toLowerCase().endsWith("_at")) && typeof this.props.data[0][e] === 'string'  && (!Array.isArray(this.props.data[0][e]))) {
               arr.push(e);
            }
        });

        return arr;
    }
   return [];
}
 
 render() {
return (
    <Drawer
        visible={this.props.visible}
        width={800}
        title={"Send Message"}
        onClose={this.props.onClose}
    >
      <Card loading={this.state.loading} bordered={false}>
      <Checkbox checked={this.state.sms} onChange={(val)=>this.setState({sms: val.target.checked})}>SMS</Checkbox> 
      <Checkbox checked={this.state.app} onChange={(val)=>this.setState({app: val.target.checked})}>Mobile App</Checkbox> 
      {/* <Checkbox checked={this.state.email} onChange={(val)=>this.setState({email: val.target.checked})}>Email</Checkbox>  */}
       <Divider/>
       <p>Recepients <Tag color="cyan">{this.props.data.length}</Tag></p>
       <Divider/>
        <p>Compose Message</p>
       <Mention  defaultSuggestions={this.getTags()} multiLines={true} style={{color: Math.ceil(this.state.message.length/160) >6 ? "red" : "black"}} onChange={(val)=>this.setState({message: Mention.toString(val)})} />
          {this.state.sms &&  <h6 style={{color: '#3F4044',float: "right",marginRight: 5}}>Characters: {this.state.message.length} Pages: {Math.ceil(this.state.message.length/160)} of 6</h6>}
        <br/> <br/>
         { ((this.state.sms || this.state.app || this.state.email) && this.state.message.trim().length>0)
          &&  <Button onClick={()=>{
              this.setState({loading: true});
             var messages = [];
             this.props.data.forEach(curMessage=> {
                var msg=this.state.message;
                Object.keys(curMessage).forEach(e => {
                  msg= msg.replace("@"+e,curMessage[e]);
                });
                var obj={};
                obj["sendEmail"] = this.state.email;
                obj["sendAppNotification"] = this.state.app;
                obj["sendSMS"] = this.state.sms;
                obj["Phone"] = curMessage.Phone;
                obj["Email"] = curMessage.Email;
                obj["Message"] = msg;
                messages.push(obj); 
             });
              
             Axios.post('/sms/send',messages)
                        .then(function (response) {
                             this.setState({
                                 loading: false,
                                 messages: []
                             });
                             this.props.onClose();
                             message.success("Delivered");
                        }.bind(this))
                        .catch(function (error) {
                            this.setState({loading: false});
                           message.error(error.message);
                        }.bind(this));
             
         }} shape="round" type="primary">Send Message</Button>}
      </Card>
    </Drawer>
)
} 
}
export default Message;