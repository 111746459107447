import React, {Component} from 'react';
import {setState, subscribe, getState, getInstance} from '../../utils/bunifu-redux';
import { DatePicker } from 'antd';
import Bootsrap from './BootSrap';
class Checkin extends Component {
state = {
   name: 'Checkin',
   loading: false
}
 componentWillMount() {
  subscribe(this);
}
 render() {
   if(this.state.loading){
            return(<div align='center' style={{width: '100%'}} class="container" >
            <br/><br/>  <br/><br/>
            <div class="spinner-grow text-success" role="status">
              <span class="sr-only">Loading...</span>
            </div><div class="spinner-grow text-success" role="status">
              <span class="sr-only">Loading...</span>
            </div><div class="spinner-grow text-success" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            </div>);
   }

return (
<div  class="container">
<Bootsrap/>
  <nav class="navbar navbar-light bg-light hidden-print">
      <a class="navbar-brand"> </a>
      <form class="form-inline">
        <DatePicker size={'large'} placeholder="Select Day" /> &nbsp;
        <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Generate Report</button>
      </form>
    </nav>
    <br/><br/>
    <img src="../img/logo2.png"  width="150"/>
    <h5><b>Student Checkin/Checkout - Dailly Report<span style={{float: 'right'}}>Date: 11/11/2019</span></b> </h5>  
        {/* <hr/> 
         <h6><b>Filters:</b> Destiny Provider</h6>
       */}
      <hr/> 
     <h6><b>Morning Schedulle (To School)</b></h6> 
    <table class="table table-striped table-sm">
  <thead class="thead-dark">
    <tr>
      <th scope="col"></th>
      <th scope="col">Student</th> 
      <th scope="col">Schedulle</th> 
      <th scope="col">Vehicle</th> 
      <th scope="col">Route</th>
      <th scope="col">Station</th>
      <th scope="col">Provider</th>
      <th scope="col">Check-In Time</th>
      <th scope="col">Check-Out Time</th>
      <th scope="col">Distance</th>
      <th scope="col">Station Cost</th>
      <th scope="col">Distance Cost</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">1</th>
      <td>Mark</td>
      <td>Schedulle 11</td> 
      <td>KCV 516F</td> 
      <td>Ngong</td>
      <td>Zambia</td>
      <td>Uber</td>
      <td>0:00</td>
      <td>0:00</td>
      <td>10KM</td>
      <td>Sh 150</td>
      <td>Sh 200</td>
    </tr>
    <tr>
      <th scope="row">2</th>
      <td>Jacob</td> 
      <td>Schedulle 11</td> 
      <td>KCV 516F</td> 
      <td>Ngong</td>
      <td>Zambia</td>
      <td>Uber</td>
      <td>0:00</td>
      <td>0:00</td>
      <td>10KM</td>
      <td>Sh 150</td>
      <td>Sh 200</td>
    </tr>
    <tr>
      <th scope="row">3</th>
      <td>Larry</td> 
      <td>Schedulle 11</td> 
      <td>KCV 516F</td> 
      <td>Ngong</td>
      <td>Zambia</td>
      <td>Uber</td>
      <td>0:00</td>
      <td>0:00</td>
      <td>10KM</td>
      <td>Sh 150</td>
      <td>Sh 200</td>
    </tr>
  </tbody>
  <thead class="thead-dark">
    <tr>
      <th scope="col"></th>
      <th scope="col"></th> 
      <th scope="col"></th> 
      <th scope="col"></th> 
      <th scope="col"></th>
      <th scope="col"></th>
      <th scope="col"></th>
      <th scope="col"></th>
      <th scope="col"></th>
      <th scope="col"></th>
      <th scope="col">Sh 450</th>
      <th scope="col">Sh 600</th>
    </tr>
  </thead>
</table>
<br/>
<hr/>   
     <h6><b>Evening Schedulle (From School)</b></h6> 
    <table class="table table-striped table-sm">
  <thead class="thead-dark">
    <tr>
      <th scope="col"></th>
      <th scope="col">Student</th> 
      <th scope="col">Schedulle</th> 
      <th scope="col">Vehicle</th> 
      <th scope="col">Route</th>
      <th scope="col">Station</th>
      <th scope="col">Provider</th>
      <th scope="col">Check-In Time</th>
      <th scope="col">Check-Out Time</th>
      <th scope="col">Distance</th>
      <th scope="col">Station Cost</th>
      <th scope="col">Distance Cost</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">1</th>
      <td>Mark</td>
      <td>Schedulle 11</td> 
      <td>KCV 516F</td> 
      <td>Ngong</td>
      <td>Zambia</td>
      <td>Uber</td>
      <td>0:00</td>
      <td>0:00</td>
      <td>10KM</td>
      <td>Sh 150</td>
      <td>Sh 200</td>
    </tr>
    <tr>
      <th scope="row">2</th>
      <td>Jacob</td> 
      <td>Schedulle 11</td> 
      <td>KCV 516F</td> 
      <td>Ngong</td>
      <td>Zambia</td>
      <td>Uber</td>
      <td>0:00</td>
      <td>0:00</td>
      <td>10KM</td>
      <td>Sh 150</td>
      <td>Sh 200</td>
    </tr>
    <tr>
      <th scope="row">3</th>
      <td>Larry</td> 
      <td>Schedulle 11</td> 
      <td>KCV 516F</td> 
      <td>Ngong</td>
      <td>Zambia</td>
      <td>Uber</td>
      <td>0:00</td>
      <td>0:00</td>
      <td>10KM</td>
      <td>Sh 150</td>
      <td>Sh 200</td>
    </tr>
  </tbody>
  <thead class="thead-dark">
    <tr>
      <th scope="col"></th>
      <th scope="col"></th> 
      <th scope="col"></th> 
      <th scope="col"></th> 
      <th scope="col"></th>
      <th scope="col"></th>
      <th scope="col"></th>
      <th scope="col"></th>
      <th scope="col"></th>
      <th scope="col"></th>
      <th scope="col">Sh 450</th>
      <th scope="col">Sh 600</th>
    </tr>
  </thead>
  <hr/>
  <thead class="thead-dark">
    <tr>
      <th scope="col">GRAND TOTAL</th>
      <th scope="col"></th> 
      <th scope="col"></th> 
      <th scope="col"></th> 
      <th scope="col"></th>
      <th scope="col"></th>
      <th scope="col"></th>
      <th scope="col"></th>
      <th scope="col"></th>
      <th scope="col"></th>
      <th scope="col">Sh 900</th>
      <th scope="col">Sh 1200</th>
    </tr>
  </thead>
</table>
</div>)
}
}
export default Checkin;