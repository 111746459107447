import {notification} from "antd";

const toast = {
    success: (message, description = null) => {
        notification['success']({
            message: message,
            description: description,
        });
    },
}

export default toast;