import React from "react";
import Ant from "antd";
import goServer from "../../../Server/GoServer";
import axios from "axios";
import _ from "linq";
import store from "../../../utils/Storage";
import GoSp from "../../../Components/GoSp";

class GoSync extends React.Component {
  constructor(props) {
    super();
    this.state = {
      endPoints: _.from(goServer.EndPoints).where((r) => (props.compact ? !r.static : true)),
      current: 0,
      isCompact: props.compact,
      props: props,
    };
  }

  isDone = () => this.state.current >= this.state.endPoints.count();
  getProgress = () =>
    Math.trunc((this.state.current / (this.state.endPoints.count() - 1)) * 100, 0);
  getCurrent = () => this.state.endPoints.skip(this.state.current).firstOrDefault();
  install = () => {
    const { isCompact, current } = this.state; 

    if (this.isDone()) {
      if (!isCompact) setTimeout(() => this.launch(), 3000);
      return;
    }

    const _endpoint = this.getCurrent();
    const _name = _endpoint.name || "";
    const _url = _endpoint.url;
    const _filter = _endpoint.filter;

    axios
      .get(_url)
      .then(async (res) => {
        let dataFiltered = res.data;
        if (_filter)
          dataFiltered = _.from(dataFiltered)
            //  .where(v => _filter(v))
            .toArray();

        store.put(_name.toLowerCase(), dataFiltered);
        this.setState({ current: current + 1 });
        await this.install();
      })
      .catch((err) => {
        //sleep for 5 sec
        if (_endpoint.defer && !isCompact) {
          store.put(_name.toLowerCase(), []);
          this.setState({ current: current + 1 });
        }

        const isNetworkIssue = !err.code || err.message.includes("Network");
        //if(isNetwork) this.setState({current: 0})
        setTimeout(() => this.install(), isNetworkIssue ? 30000 : 3000);
      });
  };

  launch = (isButton = true) => {
    if (!this.props.endPoints && !this.state.props.compact) {
      window.location.href = "/#/dashboard";
      window.location.reload();
    }
  };

  componentDidMount() {
    setTimeout(() => this.install(), 3000);
  }

  render() {
    let { current, endPoints } = this.state;
    const pass = this.getProgress();
    const isDone = this.isDone();

    if (this.state.props.compact) {
      if (isDone) return <></>;
      return (
        <Ant.Button disabled={true} type={"dashed"} shape={"round"}>
          <Ant.Icon loading={true} spin type={"sync"} />
          Syncing <GoSp /> <b>{endPoints.toArray()[current].name}</b>...[{pass}%]
        </Ant.Button>
      );
    }

    return (
      <>
        <Ant.Row style={{ marginTop: "7%" }}>
          <Ant.Col span={6}></Ant.Col>
          <Ant.Col span={12}>
            <img src={"img/logo2.png"} width={200} style={{ float: "left" }} />
            <Ant.Typography.Title
              align={"right"}
              style={{ color: "green", marginTop: 10 }}
              level={4}
            >
              {!isDone && <Ant.Icon type={"sync"} spin />}
              &nbsp;
              {isDone ? " ✔️ Done" : "Installing PWA..."}
              <br />
              <br />
              <Ant.Divider />
            </Ant.Typography.Title>

            <Ant.Typography.Title level={4}>
              <Ant.Typography.Text strong>
                Just a moment... We are working our magic behind the scenes! ☕️✨🚀
              </Ant.Typography.Text>
            </Ant.Typography.Title>
            {!isDone && (
              <>
                {" "}
                <Ant.Card
                  style={{ paddingTop: 28, paddingLeft: 28, marginTop: 33 }}
                  bordered={false}
                  className={"shadow"}
                >
                  <Ant.Progress
                    percent={pass}
                    strokeColor={{
                      "0%": "#108ee9",
                      "100%": "#87d068",
                    }}
                    width={240}
                    style={{ float: "right", marginRight: 50, padding: "4%" }}
                    type="circle"
                    status="normal"
                  />

                  <Ant.Steps progressDot direction="vertical" current={0}>
                    {endPoints
                      .skip(current)
                      .take(6)
                      .select((endpoint, idx) => (
                        <Ant.Steps.Step
                          title={this.getCurrent() == endpoint && endpoint.name}
                          description={
                            (this.getCurrent() == endpoint && "Loading...") || endpoint.name
                          }
                        />
                      ))
                      .toArray()}
                  </Ant.Steps>
                </Ant.Card>
              </>
            )}
            {isDone && (
              <Ant.Col align={"center"}>
                <br />
                <br />

                <Ant.Button size={"large"} onClick={this.launch} shape={"round"} type={"primary"}>
                  &nbsp;&nbsp;&nbsp; Launch&nbsp;&nbsp;&nbsp;
                  <strong>Bunifu-GO</strong> &nbsp;&nbsp;&nbsp; 🚀
                </Ant.Button>
                <br />
                <br />
                <Ant.Typography.Text strong disabled>
                  Redirecting in 3 seconds...
                </Ant.Typography.Text>
              </Ant.Col>
            )}
            <Ant.Col align={"center"} style={{ marginTop: 33 }}>
              <Ant.Typography.Text disabled>
                ©{window.year}, Bunifu Technologies LTD
              </Ant.Typography.Text>
            </Ant.Col>
          </Ant.Col>
        </Ant.Row>
      </>
    );
  }
}

export default GoSync;
