import React, { Component } from "react";
import {
  setState,
  subscribe,
  getState,
  getInstance,
} from "../../utils/bunifu-redux";

import Agents from "../Reports/AgentActivity";
import DailyStudentActivity from "../Reports/DailyStudentActivity";
import StudentAttendance from "../Reports/StudentAttendance";
import TripSummary from "../Reports/TripSummary";
import VehicleTrips from "../Reports/VehicleTrips";
import MissedTrips from "../Reports/MissedTrips";
import VehicleSummary from "../Reports/VehicleSummary";
import VehicleEarnings from "../Reports/VehicleEarnings";
import ProviderSummary from "../Reports/ProviderSummary";

class Reports extends Component {
  state = {};
  renderPage = (page) => {
    switch (page) {
      case "agents":
        return <Agents />;
      case "students-daily":
        return <DailyStudentActivity />;
      case "students-attendance":
        return <StudentAttendance />;
      case "student-billing":
        return "student-billing";
      case "all-vehicle-summary-earnings":
        return <VehicleEarnings />;
      case "vehicle-earnings":
        return <VehicleSummary />;
      case "trip-summary":
        return <TripSummary />;
      case "vehicle-trips":
        return <VehicleTrips />;
      case "missed-trips":
        return <MissedTrips />;
      case "provider-summary":
        return <ProviderSummary />;
      default:
        return <div>Page not found</div>;
    }
  };

  render() {
    const { page } = this.props;

    if (page == "student-billing")
    {
      return (
        <iframe
          src={"/wwwroot/?" + this.props.page}
          style={{
            width: "100%",
            height: "100%",
            border: "none",
            position: "absolute",
            top: 125,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        ></iframe>
      );
    }
    else
    {
      return (
        <div style={{ width: "100%", height: "100%", position: "relative" }}>
          {this.renderPage(page)}
        </div>
      );
    }
  }
}
export default Reports;
