import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Button, Input, Select, Empty, Spin, Col, Row } from "antd";
import {
  exportToExcel,
  exportToCSV,
  exportToPDF,
} from "../../utils/generateReports";
const { Option } = Select;

const TripSummary = ({ vehicleName: initialVehicleName }) => {
  const columns = [
    {
      title: "Vehicle",
      dataIndex: "Vehicle",
      key: "Vehicle",
    },
    {
      title: "Schedule",
      dataIndex: "Schedule",
      key: "Schedule",
    },
    {
      title: "NoOfStudents",
      dataIndex: "NoOfStudents",
      key: "NoOfStudents",
      render: (text) => (Number.isFinite(text) ? text.toLocaleString() : "0"),
    },
    {
      title: "Route",
      dataIndex: "Route",
      key: "Route",
    },
    {
      title: "Station",
      dataIndex: "Station",
      key: "Station",
    },
    {
      title: "Driver",
      dataIndex: "Driver",
      key: "Driver",
    },
    {
      title: "Agent",
      dataIndex: "Agent",
      key: "Agent",
    },
    {
      title: "Date",
      dataIndex: "Date",
      key: "Date",
    },
  ];
  const [busy, setBusy] = useState(false);
  const [text, setText] = useState("Generate Report");
  const [results, setResults] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [vehicleName, setVehicleName] = useState(initialVehicleName || "");
  const [startDate, setStartDate] = useState(
    new Date().toISOString().substr(0, 10)
  );
  const [endDate, setEndDate] = useState(
    new Date().toISOString().substr(0, 10)
  );
  const [token, setToken] = useState("");
  const [error, setError] = useState(null);
  const [pageSize, setPageSize] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [baseUrl, setBaseUrl] = useState("");

  useEffect(() => {
    const account = JSON.parse(localStorage.getItem("account"));
    const storedVehicles = JSON.parse(localStorage.getItem("vehicles"));
    const BaseUrl = localStorage.getItem("api");
    if (account && account.access_token) {
      setToken(account.access_token);
    } else {
      setError("Missing authentication token. Please log in again.");
    }
    if (storedVehicles) {
      setVehicles(storedVehicles);
    }
    if (storedVehicles.length > 0) {
      setVehicleName(storedVehicles[0].PlateNo);
    }
    if (BaseUrl) {
      setBaseUrl(baseUrl);
    } else {
      setError("Missing base URL. Please configure your settings.");
    }
  }, []);

  const calculateTotals = (data) => {
    let noOfStudents = 0;

    const uniqueVehicles = new Set();
    const uniqueSchedule = new Set();
    const uniqueRoutes = new Set();
    const uniqueStations = new Set();
    const uniqueDrivers = new Set();
    const uniqueAgents = new Set();
    const uniqueDates = new Set();

    data.forEach((item) => {
      uniqueVehicles.add(item.Vehicle);
      noOfStudents += item.NoOfStudents || 0;
      uniqueSchedule.add(item.Direction);
      uniqueRoutes.add(item.Route);
      uniqueStations.add(item.Station);
      uniqueDrivers.add(item.Driver);
      uniqueAgents.add(item.Agent);
      uniqueDates.add(item.Agent);
    });

    return {
      // Adm: "Total",
      Vehicle: `${uniqueVehicles.size} Unique Vehicles`,
      NoOfStudents: noOfStudents,
      Schedule: `${uniqueSchedule.size} Unique Schedules`,
      Route: `${uniqueRoutes.size} Unique Routes`,
      Station: `${uniqueStations.size} Unique Stations`,
      Driver: `${uniqueDrivers.size} Unique Drivers`,
      Agent: `${uniqueAgents.size} Unique Agents`,
      Date: `${uniqueDates.size} Unique Dates`,
    };
  };

  const generateReport = async (page = currentPage) => {
    setResults([]);
    setBusy(true);
    setText("Fetching data...");

    console.log("Begin data fetch...");

    try {
      const url = `${baseUrl}/reports/trips-summary?StartDate=${startDate}&EndDate=${endDate}&Vehicle=${vehicleName}`;

      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const { data } = await axios.get(url, { headers });
      console.log(data);

      const totalRow = calculateTotals(data);
      setResults([...data, totalRow]); // Append the total row at the end
    } catch (error) {
      setError(
        `Error fetching data: ${error.response?.data?.message || error.message}`
      );
    } finally {
      setBusy(false);
      setText("Generate Report");
    }
  };

  return (
    <div>
      <h4 style={{ textAlign: "center", marginBottom: 20, marginTop: 20 }}>
        Trip Summary{" "}
      </h4>
      <div style={{ width: "50%", margin: "0 auto", marginTop: 30 }}>
        <Row gutter={[16, 16]} style={{ marginBottom: "10px" }}>
          <Col span={12}>
            <label for="datePicker" style={{ paddingRight: 10 }}>
              StartDate
            </label>
            <Input
              type="date"
              cursor="pointer"
              size="large"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              style={{ width: "100%" }}
            />
          </Col>

          <Col span={12}>
            <label for="datePicker" style={{ paddingRight: 10 }}>
              EndDate
            </label>
            <Input
              type="date"
              cursor="pointer"
              size="large"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              style={{ width: "100%" }}
            />
          </Col>
          <Col span={12}>
            <label htmlFor="vehicleSelect" style={{ paddingRight: 10 }}>
              Vehicle
            </label>
            <Select
              id="vehicleSelect"
              value={vehicleName}
              onChange={(value) => setVehicleName(value)}
              placeholder="Select a Vehicle"
              size="large"
              style={{ width: "100%" }}
            >
              {vehicles.map((vehicle) => (
                <Option key={vehicle.id} value={vehicle.PlateNo}>
                  {vehicle.PlateNo}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>

        <Row justify="end">
          <Col>
            <Button
              type="primary"
              size="large"
              onClick={generateReport}
              loading={busy}
              style={{}}
              disabled={!token || busy}
            >
              Generate Report
            </Button>
          </Col>
        </Row>
      </div>

      {/* Table to display results */}
      <Spin spinning={busy}>
        {results.length > 0 ? (
          <div
            style={{
              width: "80%",
              margin: "auto",
              marginTop: "40px",
              overflowX: "auto",
            }}
          >
            <Row justify="end" style={{ marginBottom: 20 }}>
              <Col>
                <Button
                  type="primary"
                  onClick={() => exportToExcel(results, "VehicleTripSummary")}
                  style={{ marginRight: 10 }}
                >
                  Export to Excel
                </Button>
                <Button
                  type="primary"
                  onClick={() => exportToCSV(results, "VehicleTripSummary")}
                  style={{ marginRight: 10 }}
                >
                  Export to CSV
                </Button>
                <Button
                  type="primary"
                  onClick={() =>
                    exportToPDF(
                      columns,
                      results,
                      "VehicleTripSummary",
                      `VehicleTrip Summary - Vehicle: ${vehicleName || "All"}`,
                      [0, 175, 80],
                      "/img/go-green.png",
                      startDate,
                      endDate
                    )
                  }
                >
                  Export to PDF
                </Button>
              </Col>
            </Row>

            <Table
              columns={columns}
              dataSource={results}
              scroll={{ x: "max-content" }}
              pagination={{
                current: Number(currentPage),
                pageSize: Number(pageSize),
                total: results.length,
                onChange: (page, pageSize) => {
                  setCurrentPage(page);
                  setPageSize(pageSize);
                  generateReport(page, pageSize);
                },
              }}
              bordered
              style={{ marginTop: 16, minWidth: 1200 }}
            />
          </div>
        ) : (
          <div style={{ textAlign: "center", marginTop: 40 }}>
            <Empty
              description="No data available. Please generate a report."
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          </div>
        )}
      </Spin>
    </div>
  );
};

export default TripSummary;
