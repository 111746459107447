class KimTools {

    constructor() {
        this.initStringUtils();
        this.initColorUtils();
    }


    initStringUtils = () => {

        this.strings = {
            toTitleCase: (str) => !str ? "" : str.toLowerCase().replace(/\b\w/g, (match) => match.toUpperCase())

        }
    }

    initColorUtils = () => {
        //todo:  setup color utils
    }

    // public function
    sayHello() {
        alert('Hello World!')
    }
}

// Run the from the root

export default KimTools = new KimTools();
